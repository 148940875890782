export const getLocation = store => store.location
export const getMap = store => store.map
export const getBaselineList = store => store.baseline.list
export const getBaselineSelectedId = store => store.baseline.selectedId
export const getConservationList = store => store.conservationPractice.list
export const getAgroforestryList = store => store.conservationPractice.agroforestryList
export const getConservationSelectedIds = store => store.conservationPractice.selectedIds
export const getAnimalTypesList = store => store.livestock.animalTypes
export const getProductionSystemsList = store => store.livestock.productionSystems
export const getManureStorageList = store => store.livestock.manureStorage
export const getAnimalTypesSelectedIds = store => store.livestock.selectedIds
export const getFarmTypeId = store => store.baseline.farmTypeId
export const getConservationPracticeResults = store => store.conservationPractice.results
export const getLivestockResults = store => store.livestock.results
