import Dialog from '../ui/Dialog'
import { Link } from '@mui/material'
import { IoIosInformationCircle } from 'react-icons/io'
import { mutateX } from './doX'

export const LAYER_NAMES = {
  USALAYER: {
    group: 'UsaLayer',
    label: { plural: 'Usa Layers', singular: 'Usa Layer' },
    layer: 'global_layers:usa_contiguous',
    notes: '',
  },
  CLIMATE: {
    group: 'ClimateRegion',
    label: { plural: 'Climate Regions', singular: 'Climate Region' },
    layer: 'global_layers:climate_zones',
    notes: '',
  },
  SOIL_GLOBAL: {
    group: 'SoilCategory',
    label: { plural: 'Global Soils', singular: 'Global Soil' },
    layer: 'global_layers:soils_global',
    notes: '',
  },
  SOIL_NATIONAL: {
    group: 'SSoilCategory',
    label: { plural: 'National Soils', singular: 'National Soil' },
    layer: 'global_layers:soils_ssurgo',
    notes: (
      <span style={{ fontSize: '.8em', margin: 'auto' }}>
        *National Soils are only available <br /> in select locations .
        <Dialog
          header={'National Soils Layer'}
          style={{ maxWidth: 'max(95%, 900px)', margin: 'auto' }}
          btnProps={{
            component: Link,
            className: 'cpg-info-button',
            style: {
              fontSize: '1.3em',
              display: 'inline',
            },
            children: <IoIosInformationCircle />,
          }}
        >
          <div>
            National soils are available in the following locations:
            <ul style={{ padding: '.5em 2em' }}>
              {mutateX
                .alphabetArray([
                  'Marshall Islands',
                  'Puerto Rico',
                  'Virgin Islands',
                  'Northern Mariana Islands',
                  'Guam',
                  'Palau',
                  'American Samoa',
                  'Fed States of Micronesia',
                ])
                .map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
            </ul>
          </div>
        </Dialog>
      </span>
    ),
  },
  TERRITORY: {
    group: 'territory',
    label: { plural: 'Territories', singular: 'Territory' },
    layer: 'global_layers:geoboundaries',
    notes: '',
  },
}
