import './Header.css'
import pckJson from '../../../package.json'

const Welcome = props => {
  const { header, body, footer } = props
  const styles = {
    wrapper: {
      padding: '1.5em 0',
    },
    welcome: {
      width: '95%',
      maxWidth: '1080px',
      backgroundColor: 'var(--color-light)',
      margin: '1em auto',
      padding: '1.5em',
      borderRadius: '.5em',
      marginTop: `${17}px`,
      opacity: '.95',
      maxWidth: 'var(--max-width-prose)',
      position: 'relative',
    },

    version: {
      fontSize: '.8rem',
      position: 'absolute',
      bottom: '.5rem',
      right: '.5rem',
    },
  }

  return (
    <div className="ax-header img" style={styles.wrapper}>
      <div className="header-banner-container ax-header-transition" style={styles.welcome}>
        <h2>{header}</h2>
        {body && body}
        {footer && <br />}
        {footer && footer}
        <div style={styles.version}>Version: {pckJson.version}</div>
      </div>
    </div>
  )
}
export default Welcome
