import React from 'react'
import './ToolTipSpan.css'

export const ButtonSlide = props => {
  return (
    <button
      className={`ax-axuserinput-axbtniconstretch ax-iconbtns-transition`}
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
    >
      <div className="ax-iconbtns-child-wrap">
        <label style={{ fontSize: '.8em' }} className="ax-iconbtns-transition desktop-only">
          {props.label}
        </label>
        {props.img}
      </div>
    </button>
  )
}

export const DownloadButton = props => {
  const { onClick, disabled, label, style } = props
  return (
    <ButtonSlide
      style={style}
      label={label || 'Download Report'}
      className={'ax-tooltipbutton-img'}
      img={<IconDownload />}
      onClick={onClick}
      disabled={disabled}
    />
  )
}

export const DownloadButtonNoSlide = props => {
  return (
    <button onClick={props.onClick} style={props.style} disabled={props.disabled}>
      <div className="ax-iconbtns-child-wrap">
        <IconDownload />
        <label style={{ fontSize: '.8em', marginLeft: '.8em' }}>{props.label}</label>
      </div>
    </button>
  )
}

export const ClearSelectionButton = props => {
  const { onClick, disabled, label, style } = props
  return (
    <ButtonSlide
      style={style}
      label={label || 'Clear Selections'}
      className={'ax-tooltipbutton-img'}
      img={<IconTrash />}
      onClick={onClick}
      disabled={disabled}
    />
  )
}

export const DetailedReportBtn = props => {
  const { onClick, disabled, label, style } = props
  return (
    <ButtonSlide
      style={style}
      label={label || 'Detailed Report'}
      // className={'ax-tooltipbutton-img'}
      img={<DetailedTableIcon />}
      onClick={onClick}
      disabled={disabled}
    />
  )
}

export default ButtonSlide

const DetailedTableIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 256 256"
    height="1.5em"
    width="1.5em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M88,104v96H32V104Z" opacity="0.2"></path>
    <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM40,112H80v32H40Zm56,0H216v32H96ZM216,64V96H40V64ZM40,160H80v32H40Zm176,32H96V160H216v32Z"></path>
  </svg>
)
const IconTrash = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    className="svg-inline--fa fa-trash-alt fa-w-14"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    height="1.05em"
    width="1.05em"
  >
    <path
      fill="currentColor"
      d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
    ></path>
  </svg>
)

const IconDownload = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height="1.5em" width="1.5em">
    <g>
      <g>
        <polygon
          fill="currentColor"
          points="18,13.5 14,13.5 14,21.5 11,21.5 16,27.5 21,21.5 18,21.5 		"
        />
        <path
          fill="currentColor"
          d="M27.586,12.712C26.66,10.251,24.284,8.5,21.5,8.5c-0.641,0-1.26,0.093-1.846,0.266
			C18.068,6.205,15.233,4.5,12,4.5c-4.904,0-8.894,3.924-8.998,8.803C1.207,14.342,0,16.283,0,18.5c0,3.312,2.688,6,6,6h4.5
			l-1.667-2H5.997C3.794,22.5,2,20.709,2,18.5c0-1.893,1.317-3.482,3.087-3.896C5.029,14.245,5,13.876,5,13.5c0-3.866,3.134-7,7-7
			c3.162,0,5.834,2.097,6.702,4.975c0.769-0.611,1.739-0.975,2.798-0.975c2.316,0,4.225,1.75,4.473,4h0.03
			c2.203,0,3.997,1.791,3.997,4c0,2.205-1.789,4-3.997,4h-2.836l-1.667,2H26c3.312,0,6-2.693,6-6
			C32,15.735,30.13,13.407,27.586,12.712z"
        />
      </g>
    </g>
  </svg>
)
