import React from 'react'

export const isX = {
  isEmpty(x) {
    if (x === null) return true
    if (x === '') return true
    if (Array.isArray(x) && x.length === 0) return true
    if (this.isObject(x) && Object.keys[x].length === 0) return true
    return false
  },
  isNonEmptyArray(a) {
    return Array.isArray(a) && a.length > 0
  },
  isArrayOfMinLength(a, len) {
    return Array.isArray(a) && a.length >= (!isNaN(len) ? len : 0)
  },

  isFunct(x) {
    return x instanceof Function || typeof x === 'function'
  },
  isPrimitive(o, ...args) {
    return (val => {
      if (val === undefined) return false
      if (val === null) return true
      if (typeof val === 'object' || typeof val === 'function') return false
      return true
    })(getX.any(o, ...args))
  },
  isObject(o, ...args) {
    return (val =>
      typeof val === 'object' &&
      val !== null &&
      !Array.isArray(val) &&
      Object.keys(val).length >= 1)(getX.any(o, ...args))
  },
  isString(o, ...args) {
    const val = getX.any(o, ...args)
    return typeof val === 'string' || val instanceof String
  },
  isReactElement(o, ...args) {
    const val = getX.any(o, ...args)
    return React.isValidElement(val)
  },
  isValid_DataType(o, ...args) {
    const val = getX.any(o, ...args, 'value') || getX.any(o, ...args)
    return typeof val === 'string' || typeof val === 'number' ? true : false
  },
  isNumeric(o, ...args) {
    const str = getX.value(o, ...args)
    if (str === undefined || str === null || str === '') return false
    if (!isNaN(str)) return true
    if (typeof str != 'string') return false // we only process strings from here
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ) // ...and ensure strings of whitespace fail
  },
  isEvenNumber(o, ...args) {
    const str = getX.value(o, ...args)
    if (!this.isNumeric(str)) return false
    return str % 2 === 0
  },
  isNotNullorUndefined(o, ...args) {
    const val = getX.value(o, ...args)
    if (val === null || val === undefined) return false
    return true
  },
  isStringsEqual(a, b) {
    if (!(this.isString(a) && this.isString(b))) return false
    return a?.toString().toLowerCase() === b?.toString().toLowerCase()
  },
  isBoolean(a) {
    return typeof a === 'boolean'
  },
}

export const getX = {
  /**
   * @any returns value in a nested object. Each level is access by [arg.toString()] if any level fails, it returns undefined
   *
   *           examples:
   *           let exampleObject = {test:{example:4,foo:{bar:[0,11,22,33]}}}
   *
   *           exampleObject['test']['example']['foo'] === getX.any(exampleObject, 'test','example','foo')
   *
   */
  any(o, ...args) {
    return args.reduce((obj, level) => obj && obj[level], o)
  },

  primitive(o, ...args) {
    return val => (isX.isPrimitive(val) ? val : null)(this.any(o, ...args))
  },

  hasValue(o, ...args) {
    return (val => this.any(val, 'value') !== undefined)(this.any(o, ...args))
  },

  value(o, ...args) {
    return (val =>
      isX.isPrimitive(val) || React.isValidElement(val) || this.any(val, 'value') === undefined
        ? val
        : val.value)(this.any(o, ...args))
  },

  nestedValue(o, ...args) {
    return (val => (this.hasValue(val) ? this.nestedValue(val?.value) : val))(
      this.value(o, ...args)
    )
    // return (val => (this.hasValue(val) ? this.nestedValue(val, 'value') : val))(this.value(o, ...args))
  },

  basicHtmlAttribs(o, ...args) {
    const val = this.object(o, ...args)
    const attribs = (({ label, style, className, onClick, name, id }) => ({
      label,
      style,
      className,
      onClick,
      name,
      id,
    }))(val)
    return attribs
  },
  spaceOL(i) {
    const space = '\u00A0'
    let returnSpaces = space
    if (Number.isInteger(i)) {
      for (let k = 1; k < i; k++) {
        returnSpaces += space
      }
    }
    return returnSpaces
  },
  checkObjOfBools(obj) {
    const bools = Object.keys(this.object(obj)).map(key => obj[key])
    return this.array(bools).every(x => x)
  },

  callFunctOr(f, props, alternative) {
    return isX.isFunct(f) && (props || props === false) ? f(props) : alternative || false
  },

  object(o, ...args) {
    const obj = this.any(o, ...args)
    return isX.isObject(obj) ? obj : {}
  },

  string(o, ...args) {
    const val = this.any(o, ...args)
    return isX.isString(val) ? val : ''
  },

  function(funct) {
    return isX.isFunct(funct)
      ? funct
      : () => console.error('357 getX didnt find a function. Instead found: ', funct)
  },

  array(o, ...args) {
    return isX.isNonEmptyArray(this.value(o, ...args)) ? this.value(o, ...args) : []
  },
  map(funct, o, ...args) {
    return this.array(o, ...args).map((a, i) => funct(a, i))
  },
  filter(funct, o, ...args) {
    return this.array(o, ...args).filter((a, i) => funct(a, i))
  },
  filterArrayOfObjectsByIds(ids, o, ...args) {
    return this.array(o, ...args).filter(a => ids.includes(a.id))
  },

  integerOrFalse(e, ...args) {
    return parseInt(this.any(e, ...args)) || false
  },

  integerOr1(e, ...args) {
    return this.integerOrFalse(e, ...args) || 1
  },

  integerOrZero(e, ...args) {
    return this.integerOrFalse(e, ...args) || 0
  },

  cleanFileName(s) {
    /* -- remove all special characters and replace repeated spaces with _ */
    const str2cleanse = this.string(s) || 'filename'
    return str2cleanse.replace(/[^\w\s]/g, '').replace(/\s+/g, '_')
  },

  nonEmptyObjectCheck(o) {
    return (o && !(Object.entries(o).length === 0 && o.constructor === Object) && o) || false
  },

  exportArrOfArrToCsvWithFileName(rows, fileName) {
    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n')
    var encodedUri = encodeURI(csvContent)
    if (fileName) {
      var link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `${getX.cleanFileName(fileName)}`)
      document.body.appendChild(link) // Required for FF

      link.click() // This will download the data file named "my_data.csv".
    } else {
      window.open(encodedUri)
    }
  },

  reactKey(index, str) {
    /**
     * TODO:
     * Clean response to be always an exceptable react key string
     * i.e. check for string or integer; regex remove holy characters
     */
    // let key = i.toString() || ""

    return `react_key_${index}_${str}`
  },

  recurSum(n) {
    if (n <= 1) return n
    return n + this.recurSum(n - 1)
  },

  getUniqueValuesFromArray(arr) {
    let newArr = []
    this.array(arr).forEach(a => {
      newArr.findIndex(b => a === b) < 0 && newArr.push(a)
    })
    return newArr
  },
  async fetchData(url, callback) {
    try {
      const response = await fetch(url)

      if (!response.ok) {
        throw new Error(`Network response was nope (status: ${response.status})`)
      }

      const data = await response.json()
      if (isX.isFunct(callback)) callback(data)
      return data
    } catch (error) {
      console.error('Error fetching data:', error.message)
      throw error // Re-throw the error to propagate it further if needed
    }
  },
  async postRequest(url, body, callback) {
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => {
        if (response.ok) {
          const data = response.json()
          return isX.isFunct(callback) ? callback(data) : data
        } else {
          throw new Error('Failed to post')
        }
      })
      .catch(error => console.error('Error in POST:', error))
  },
}

export const convertX = {
  arrOfObjects2ArrayOfArrays(a, keys) {
    if (isX.isNonEmptyArray(a) && isX.isNonEmptyArray(keys))
      return getX.array(a).map(o => (keys || Object.keys(o).sort()).map(k => o[k]))
  },

  formatKeyValuePair(obj = {}, callBack) {
    return Object.entries(obj).reduce(
      (accumulatedPairs, [key, value]) => ({
        ...accumulatedPairs,
        [key]: callBack({ key, value }),
      }),
      {}
    )
  },

  txt2LowerNoSpaces(string) {
    return string?.toString().replace(/\s/g, '').toLowerCase()
  },
  txt2UpperNoSpaces(string) {
    return string?.toString().replace(/\s/g, '').toUpperCase()
  },
  txt2currency(string) {
    const formatCur = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    return formatCur.format(string)
  },
  txt2NumberWithComma(string) {
    const formatCur = new Intl.NumberFormat('en-US')
    return formatCur.format(string)
  },
  csv2ArrayOfArrays() {
    const csv = `AA01,BB00,CC01,ZZ0
      AA02,BB01,CC02,ZZ02
      AA03,BB02,CC03,what happens if you messup,ZZ03`
    // Convert the data to String and
    // split it in an array
    var array = csv.toString().split('\r')

    // All the rows of the CSV will be
    // converted to JSON objects which
    // will be added to result in an array
    let result = []

    // The array[0] contains all the
    // header columns so we store them
    // in headers array
    let headers = array[0].split(', ')

    // Since headers are separated, we
    // need to traverse remaining n-1 rows.
    for (let i = 1; i < array.length - 1; i++) {
      let obj = {}

      // Create an empty object to later add
      // values of the current row to it
      // Declare string str as current array
      // value to change the delimiter and
      // store the generated string in a new
      // string s
      let str = array[i]
      let s = ''

      // By Default, we get the comma separated
      // values of a cell in quotes " " so we
      // use flag to keep track of quotes and
      // split the string accordingly
      // If we encounter opening quote (")
      // then we keep commas as it is otherwise
      // we replace them with pipe |
      // We keep adding the characters we
      // traverse to a String s
      let flag = 0
      for (let ch of str) {
        if (ch === '"' && flag === 0) {
          flag = 1
        } else if (ch === '"' && flag === 1) flag = 0
        if (ch === ', ' && flag === 0) ch = '|'
        if (ch !== '"') s += ch
      }

      // Split the string using pipe delimiter |
      // and store the values in a properties array
      let properties = s.split('|')

      // For each header, if the value contains
      // multiple comma separated data, then we
      // store it in the form of array otherwise
      // directly the value is stored
      for (let j in headers) {
        if (properties[j].includes(', ')) {
          obj[headers[j]] = properties[j].split(', ').map(item => item.trim())
        } else obj[headers[j]] = properties[j]
      }

      // Add the generated object to our
      // result array
      result.push(obj)
    }

    // Convert the resultant array to json and
    // generate the JSON output file.
    // let json = JSON.stringify(result)
    // fs.writeFileSync("output.json", json)
  },
}

const dz = z => z || '-'
const tz = z => z || ''
export const getKey = {
  currDateYYYY_MM_DD_HHMMSS(dl, tl, d = new Date()) {
    return `${
      d.getFullYear() +
      dz(dl) +
      `${d.getMonth() + 1}` +
      dz(dl) +
      d.getDate() +
      ' ' +
      d.getHours() +
      tz(tl) +
      d.getMinutes() +
      tz(tl) +
      d.getSeconds()
    }`
  },

  dateMM_DD__YYYY(d = new Date()) {
    const date = new Date()
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    const formattedDate = date.toLocaleDateString('en-US', options)
    return formattedDate
  },
  date_key() {
    return `${Date.now()}${(1e9 * Math.random()) >>> 0}`
  },
  short() {
    return (1e4 * Math.random()) >>> 0
  },
  medium() {
    return (1e9 * Math.random()) >>> 0
  },
  hex(i) {
    const hexVals = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F']
    let hex = ''
    do {
      hex += hexVals[Math.floor(Math.random() * 16)]
    } while (hex.length < (i || 6))
    return hex
  },
  hexColor() {
    return '#' + this.hex(6)
  },

  currDateMMDDYYYYHHMM(d = new Date()) {
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
  },
}

export function sortByKeys(keys) {
  return function (a, b) {
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]?.key
      const sortOrder = keys[i]?.ascending === true ? 1 : -1

      const comparison = a[key]?.toString().localeCompare(b[key]?.toString()) * sortOrder

      if (comparison !== 0) {
        return comparison
      }
    }
    return 0
  }
}

export const mutateX = {
  arrayUniquePrimitives(arr) {
    return getX.array(arr).filter((value, index, self) => self.indexOf(value) === index)
  },
  cloneObj(obj) {
    if (Array.isArray(obj)) return obj.map(e => this.cloneObj(e))
    if (obj === null || typeof obj !== 'object') return obj
    const cloned = {}
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) cloned[key] = this.cloneObj(obj[key])
    }
    return cloned
  },
  cloneArray(obj) {
    return Array.isArray(obj) ? this.cloneObj(obj) : []
  },

  arPushUniqueId(arr, next) {
    const a = this.cloneObj(getX.array(arr))
    a.findIndex(a => a.id === next.id) < 0 && a.push(next)
    return a
  },

  arRmvAllById(arr, id, value) {
    const a = this.cloneObj(getX.array(arr))
    let index = a.findIndex(e => (value ? e[value] : e.id) === id)
    while (index > -1) {
      a.splice(index, 1)
      index = a.findIndex(e => (value ? e[value] : e.id) === id)
    }
    return a
  },

  arRmvAllByValue(arr, val) {
    const a = this.cloneObj(getX.array(arr))
    let index = a.findIndex(e => e === val)
    while (index > -1) {
      a.splice(index, 1)
      index = a.findIndex(e => e === val)
    }
    return a
  },

  addRemoveObjFromArrayById(arr, obj) {
    const a = this.cloneObj(getX.array(arr))
    let index = a.findIndex(e => e.id === obj.id)
    if (index < 0) {
      a.push(obj)
      return a
    } else {
      return this.arRmvAllById(a, obj.id)
    }
  },

  addRemoveValueFromArray(arr, val) {
    const a = this.cloneObj(getX.array(arr))
    let index = a.findIndex(e => e === val)
    if (index < 0) {
      a.push(val)
      return a
    } else {
      return this.arRmvAllByValue(a, val)
    }
  },

  spliceArrayOfObjects(array, objectKey, objectValue) {
    const nextArray = array.filter(function (obj) {
      return obj[objectKey] != objectValue
    })
    return nextArray
  },

  valueInArray(val, arr) {
    if (isX.isNonEmptyArray(arr)) {
      return arr.findIndex(el => el === val) > -1
    }
    return false
  },

  findIndexOfObjs(arr, key, value) {
    return getX.array(arr).findIndex(a => a[key] === value)
  },

  alphabetArray(objArray) {
    return getX.array(objArray).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  },
  alphabetObjArray(objArray, objectKey) {
    return getX
      .array(objArray)
      .sort((a, b) => a[objectKey].toLowerCase().localeCompare(b[objectKey].toLowerCase()))
  },
  descAlphabetObjArray(objArray, objectKey) {
    return getX
      .array(objArray)
      .sort((b, a) => a[objectKey].toLowerCase().localeCompare(b[objectKey].toLowerCase()))
  },
  formatDateToYYYYMMDD(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}/${month}/${day}`
  },
  formatDateTimeToYYYYMMDDHHMMSS(date) {
    const dateString = this.formatDateToYYYYMMDD(date)
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${dateString} ${hours}:${minutes}:${seconds}`
  },
}

export const sortX = {
  compareStrings(a, b) {
    return a?.toString().toLowerCase().localeCompare(b.toString().toLowerCase())
  },
  compareNumbers(a, b) {
    return a - b
  },
  sortByKeyAscString(objArray, key) {
    return getX.array(objArray).sort((a, b) => this.compareStrings(a[key], b[key]))
  },
  sortByKeyDescString(objArray, key) {
    return getX.array(objArray).sort((a, b) => this.compareStrings(b[key], a[key]))
  },
  sortByKeyAscNumeric(objArray, key) {
    return getX.array(objArray).sort((a, b) => this.compareNumbers(a[key], b[key]))
  },
  sortByKeyDescNumeric(objArray, key) {
    return getX.array(objArray).sort((a, b) => this.compareNumbers(b[key], a[key]))
  },
  compareByKeyAb(a, b, key) {
    if (!isNaN(a[key]) && !isNaN(b[key])) return this.compareNumbers(a[key], b[key])
    return this.compareStrings(a[key], b[key])
  },
  sortByKeyAsc(objArray, key) {
    return getX.array(objArray).sort((a, b) => this.compareByKeyAb(a, b, key))
  },
  sortByKeyDesc(objArray, key) {
    return getX.array(objArray).sort((b, a) => this.compareByKeyAb(a, b, key))
  },
  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  },
}

export const exampleX = {
  lorem(key) {
    switch (key) {
      case 'short':
        return (
          <>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni quia odio tenetur
            laboriosam voluptatem! Non, optio rem. Nam necessitatibus, minus quis magnam
            accusamus velit aliquid?{' '}
          </>
        )
      case 'medium':
        return (
          <>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur debitis iure
              esse? Numquam sed nemo harum doloribus. Nesciunt aperiam, ipsa temporibus cum
              sunt porro itaque optio veritatis accusamus consequatur quidem eos voluptatum
              voluptate inventore saepe vel earum atque repellat, est ut iusto illum! Numquam,
              nostrum modi. Quod, explicabo. Aspernatur deserunt suscipit non culpa tenetur
              accusantium totam fugit, nemo excepturi odit!
            </p>
            <br />
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur debitis iure
              esse? Numquam sed nemo harum doloribus. Nesciunt aperiam, ipsa temporibus cum
              sunt porro itaque optio veritatis accusamus consequatur quidem eos voluptatum
              voluptate inventore saepe vel earum atque repellat, est ut iusto illum! Numquam,
              nostrum modi. Quod, explicabo. Aspernatur deserunt suscipit non culpa tenetur
              accusantium totam fugit, nemo excepturi odit!
            </p>
          </>
        )
      case 'long':
        return (
          <>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odio dolorum, facilis
              reprehenderit autem, praesentium doloremque mollitia modi odit quod iste minus
              deleniti sint esse? Laborum aut quia deserunt delectus error mollitia sit
              asperiores facere debitis ullam, et nemo expedita libero perspiciatis incidunt
              aspernatur culpa obcaecati in corporis. Error voluptatem amet qui enim, cumque ut
              officia sit officiis natus rem ipsam eos asperiores sed reprehenderit autem, fuga
              a doloremque! Fugit ipsum consequatur quis excepturi quos laboriosam recusandae
              soluta autem, ad asperiores? Cumque omnis facilis itaque officiis at tempore.
              Inventore beatae nihil deleniti fugit, eius culpa dolor, omnis harum, quos
              nesciunt delectus id. Esse dicta numquam expedita quisquam incidunt eius eaque
              exercitationem voluptates a ad, vero, maxime minus nobis. Odio libero excepturi
              dignissimos iste facere quisquam facilis, totam sed officia, ut iusto adipisci ex
              minus quos omnis? Magni, officiis omnis. Maiores excepturi expedita sint at?
              Corporis, debitis quam magni laboriosam unde quaerat explicabo quae cum impedit
              mollitia hic quasi enim excepturi! Commodi veniam molestias asperiores. Id ipsam
              ipsum earum, voluptates corporis aspernatur similique, facere deserunt mollitia
              odio dolores omnis nam delectus sapiente officia veritatis libero quaerat
              expedita quasi sed fuga provident. Ut mollitia quae architecto ab. Nulla sapiente
              aspernatur reiciendis animi, ab obcaecati quo consequatur vel ut doloremque
              distinctio? Earum commodi dolorem consequuntur similique provident, magni illo
              dolorum, excepturi fugit officia dolores, quibusdam ullam! Possimus commodi rerum
              laudantium, in hic illo illum assumenda, saepe inventore repudiandae deserunt?
              Sunt, omnis ab natus totam repellat magnam magni ullam enim sapiente voluptates?
              Fugit fugiat magnam molestias perferendis sapiente exercitationem nesciunt,
              repudiandae quisquam alias provident ad nisi culpa. Aspernatur, itaque ullam sint
              quod natus porro laudantium, adipisci asperiores facere molestiae, dolor ipsam
              reiciendis iste voluptate repudiandae hic quae similique exercitationem ex.
              Tempore illum alias in maxime voluptatibus architecto accusamus eos qui
              doloremque voluptatum pariatur porro velit rerum distinctio voluptatem culpa
              recusandae ipsum ipsam, inventore, quisquam nesciunt perferendis id aperiam
              tenetur. Expedita reiciendis velit ab earum numquam nostrum, hic at, eaque
              distinctio, sunt eius laudantium quidem blanditiis consequuntur repellat itaque
              tenetur ipsam explicabo odit voluptas! Nostrum laborum ullam explicabo blanditiis
              labore cum, animi tenetur et! Excepturi, ut! Quidem tenetur facilis nemo
              laudantium saepe nihil! Repellendus odio corporis itaque consequuntur culpa,
              quibusdam rem quis laboriosam ipsum, consectetur rerum cumque soluta, deleniti
              non nihil optio saepe qui iste ex est perspiciatis vitae! Exercitationem quod
              culpa ex consequuntur adipisci sequi reprehenderit omnis fugiat iure eligendi in
              impedit velit, repellat explicabo!
            </p>
            <br />
            <br />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id repellendus aliquid
              neque rem cupiditate sunt pariatur velit, tempore distinctio natus illo cumque
              temporibus? Maiores laudantium repellendus, quis qui blanditiis placeat sunt
              voluptatibus omnis doloremque, unde facere delectus assumenda numquam earum
              repudiandae molestiae eligendi dolores? Vitae aperiam dolores aliquam voluptatem
              quae modi tempore, non explicabo facere voluptatum recusandae eligendi assumenda
              omnis soluta labore cum error repellat a sit reiciendis aspernatur. Repellat
              numquam magnam labore adipisci aliquid ducimus ullam voluptate nesciunt
              asperiores deleniti quos, eligendi in esse odio accusamus, corrupti iste sunt!
              Explicabo molestias laborum nemo corrupti eveniet magnam accusantium doloremque
              iusto! Ratione voluptatum itaque eligendi, perspiciatis esse, cupiditate dolore
              incidunt, quod accusantium similique animi ipsam sed et eius placeat omnis
              consequuntur possimus quaerat necessitatibus repellat eum ipsum quibusdam
              repellendus suscipit. Quasi quae ratione nihil iure explicabo praesentium odio
              similique. Et debitis adipisci, vero quod esse quaerat. Voluptas hic assumenda
              minus optio facere quaerat excepturi ducimus vel illum iure nisi aspernatur
              architecto porro, id maxime totam quidem error velit recusandae fugiat accusamus.
              Incidunt impedit ut placeat corrupti earum optio aliquid. Corrupti pariatur
              explicabo ab. Facere ut autem optio tenetur. Consequatur quos harum, praesentium
              iusto ipsam cumque libero nihil sint dolore sequi veniam ut in fugit perferendis
              ducimus quod! Aliquam quis similique illo natus sequi quae beatae! Quisquam
              numquam est eligendi, sapiente, quam mollitia, excepturi eveniet earum illum hic
              asperiores architecto porro odit assumenda esse dignissimos! At odit
              reprehenderit modi omnis? Doloremque, nemo accusamus dolor, quia natus unde
              obcaecati architecto eum explicabo, molestiae molestias consequatur delectus!
              Numquam, laboriosam distinctio facere velit est exercitationem eius autem, rerum
              repellat enim ut laudantium amet non vitae pariatur soluta, illo illum dolores
              nemo ad. Ipsa nostrum, possimus enim amet, eum repudiandae, iste cumque veritatis
              soluta magnam porro molestias accusamus officiis? Atque vero aspernatur sunt
              obcaecati corrupti quisquam officiis repudiandae ut natus neque quam, velit
              maxime delectus dicta eveniet similique numquam assumenda itaque! A laudantium,
              aliquid reiciendis facilis amet ex corrupti non illo itaque ea voluptatum magnam
              totam deserunt quaerat nesciunt quam dolore fugiat asperiores obcaecati minima,
              veritatis ab tenetur doloribus! Ipsa assumenda sit ut voluptatem similique
              obcaecati, in reprehenderit distinctio omnis explicabo repudiandae amet laborum
              officia sunt. Libero facilis commodi veritatis? Perferendis non voluptates
              provident cupiditate rem commodi recusandae modi perspiciatis ducimus quod minus,
              tempore, iste alias facilis voluptas veniam quae veritatis quasi? Nostrum optio,
              explicabo porro beatae unde maiores molestias nihil quia! Explicabo praesentium
              iusto saepe accusamus, ipsam quibusdam ab veniam consequuntur? Hic velit odio
              incidunt, aperiam natus similique amet consequatur, tempore quia officiis
              consequuntur enim. Perspiciatis voluptates natus placeat alias nihil at esse
              consequuntur facere explicabo quaerat magnam, totam enim illum, soluta officiis
              est, quis unde aliquam aut asperiores velit fugiat. Dolor, ad! Quod aliquam eius
              sequi est non exercitationem? Consequuntur error aliquid hic deserunt. Est illo
              corporis voluptatem minima illum debitis hic aliquam consequatur officiis non eos
              quam modi, eius vitae fuga eligendi sed ipsum quas cumque aspernatur eveniet?
              Porro cupiditate excepturi ut reiciendis et, dolorem ad assumenda facere rem,
              commodi vero architecto nemo!
            </p>
          </>
        )

      default:
        break
    }
  },
}
