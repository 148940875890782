import React from 'react'
import { Button } from '../../ui/Form'
import './LandUse.css'

import { useDispatch, useSelector } from 'react-redux'
import { setBaseline } from '../../../reducers/baseline.js'
import {
  getBaselineList,
  getBaselineSelectedId,
  getLocation,
} from '../../../store/selectors.js'
import Dialog from '../../ui/Dialog.jsx'
import mapLayerExampleSrc from '../../../assets/maplayerexample.png'
import { IoIosInformationCircle } from 'react-icons/io'
import { LANDUSE_IMAGES } from '../../../constants'
import { Alert, AlertTitle } from '@mui/material'

const LandUse = () => {
  const baselineList = useSelector(getBaselineList)
  const baselineSelectedId = useSelector(getBaselineSelectedId)
  const location = useSelector(getLocation)
  const dispatch = useDispatch()

  const updateBaseline = selection => {
    if (selection !== baselineSelectedId) {
      dispatch(setBaseline(selection))
    }
  }
  /**
   * TODO: Add LiveStock back in
   *  - Removing Animal Ag for now. so we can push a version with geoserver
   */
  const landUses = baselineList
    .filter(e => e.id !== 8) // Removing Livestock for now
    .map(e => ({ ...e, src: LANDUSE_IMAGES[e?.id] }))

  const incompatibleArrayIds = (() => {
    const cropBaselineIds = [1, 2]
    const livestockBaselineIds = [8]
    const isCropIncompat = location?.soil?.isNoData || location?.climate?.isNoData
    const isLivestockIncompat = location?.climate?.isNoData
    const workingArray = []
    if (isCropIncompat) workingArray.push(...cropBaselineIds)
    if (isLivestockIncompat) workingArray.push(...livestockBaselineIds)
    return workingArray
  })()

  const incompatLandUseNames = baselineList
    .filter(e => {
      return incompatibleArrayIds.some(k => k === e?.id)
    })
    .map(e => e?.name)

  return (
    <>
      <LandUseIncompatible names={incompatLandUseNames} />
      <div className="landuse-component" style={{ paddingBottom: '.5em' }}>
        {landUses.map((e, i) => {
          return (
            <React.Fragment key={i + 'landusebutton_key'}>
              <Button
                {...e}
                disabled={incompatibleArrayIds.findIndex(k => k === e?.id) > -1}
                onClick={() => updateBaseline(e?.id)}
                className={`${
                  baselineSelectedId === e?.id ? 'active' : ''
                } ax-flex column flex-grow landuse-btn`}
              >
                <img alt={e?.name} src={e?.src} className="landuse-image" />
                <span>{e?.name}</span>
              </Button>
            </React.Fragment>
          )
        })}
      </div>
    </>
  )
}

export default LandUse

const LandUseIncompatible = ({ names }) => (
  <Dialog
    header={'Missing Data'}
    btnProps={{
      sx: { fontSize: '.9em', fontWeight: '400', cursor: 'pointer' },
      children: (
        <div
          className={` show-hide-height ${names.length > 0 ? 'show' : ''}`}
          style={{ maxHeight: '4em' }}
        >
          <Alert severity="info" className={`prose`}>
            <AlertTitle>
              Some Land Uses are not available with your current Farm Location{' '}
              <IoIosInformationCircle />
            </AlertTitle>
          </Alert>
        </div>
      ),
    }}
    children={
      <>
        {names.length > 0 && (
          <>
            <b>{names.join(', ')} </b>
            {names.length > 1 ? ' are ' : ' is '}
            not available at your current <b> Farm Location</b>.
          </>
        )}
        <br />
        <br />
        The <b>Farm Location </b> you have selected has no climate or soil data or data that is
        incompatible with the tool's calculations. To view the climate and soil data at your
        selected location, you can use the <b> Map Layers </b>legend at the top right of the
        map.
        <br />
        <br />
        <img src={mapLayerExampleSrc} style={{ width: '100%', borderRadius: '7px' }} />
      </>
    }
  />
)
