export const coefKeys = [
  {
    id: 'name',
    label: 'Conservation Practice',
    category: 'conservationPractice',
  },
  {
    id: 'animalType',
    label: 'Animal Type',
    category: 'livestockPractice',
  },
  {
    id: 'biomassCo2',
    label: 'Biomass<br>Carbon',
    category: 'conservationPractice',
  },
  {
    id: 'fossilCo2',
    label: "Fossil<br>CO<sub style='font-size:.8em'>2</sub>",
    category: 'conservationPractice',
  },
  {
    id: 'biomassburningCo2',
    label: "Biomass<br>Burning CO<sub style='font-size:.8em'>2</sub>",
    category: 'conservationPractice',
  },
  {
    id: 'limingCo2',
    label: 'Liming',
    category: 'conservationPractice',
  },
  {
    id: 'soilCarbonCo2',
    label: 'Soil<br>Carbon',
    category: 'conservationPractice',
  },
  {
    id: 'directSoilN2O',
    label: "Direct<br>Soil N<sub style='font-size:.8em'>2</sub>O",
    category: 'conservationPractice',
  },
  {
    id: 'indirectSoilN2O',
    label: "Indirect<br>Soil N<sub style='font-size:.8em'>2</sub>O",
    category: 'conservationPractice',
  },
  {
    id: 'biomassburningN2O',
    label: "Biomass<br>Burning N<sub style='font-size:.8em'>2</sub>O",
    category: 'conservationPractice',
  },
  {
    id: 'soilCh4',
    label: "Soil<br>CH<sub style='font-size:.8em'>4</sub>",
    category: 'conservationPractice',
  },
  {
    id: 'biomassburningCh4',
    label: "Biomass<br>Burning CH<sub style='font-size:.8em'>4</sub>",
    category: 'conservationPractice',
  },
  {
    id: 'entericCh4',
    label: "Enteric CH<sub style='font-size:.8em'>4</sub>",
    category: 'livestockPractice',
  },
  {
    id: 'manureCh4',
    label: "Manure CH<sub style='font-size:.8em'>4</sub>",
    category: 'livestockPractice',
  },
  {
    id: 'directN2o',
    label: "Direct <br>N<sub style='font-size:.8em'>2</sub>O",
    category: 'livestockPractice',
  },
  {
    id: 'indirectN2oL',
    label: "Indirect N<sub style='font-size:.8em'>2</sub>O <br>from leaching",
    category: 'livestockPractice',
  },
  {
    id: 'indirectN2oV',
    label: "Indirect N<sub style='font-size:.8em'>2</sub>O <br>from volatilization",
    category: 'livestockPractice',
  },
  {
    id: 'totalGhgCo2',
    label: 'Total CO<sub>2</sub><br>Equivalent',
    category: 'all',
  },
  // {
  //     id: "totalGhgCo2_min",
  //     label: "Minimum Total<br>Emission Reductions",
  // },
  // {
  //     id: "totalGhgCo2_max",
  //     label: "Maximum Total<br>Emission Reductions",
  // },
]
