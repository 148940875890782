import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  centerLat: null,
  centerLon: null,
  zoom: null,
  selectedLayer: null,
  countryId: null,
  /* --- Climate layer --- */
  climateLookups: null,
  climateLoading: null,
  climateLoadedCountries: [],
  climateLoadedIds: [],
  /* --- Basic Soil layer --- */
  soilLookups: null,
  soilLoading: null,
  soilLoadedCountries: [],
  soilLoadedIds: [],
  /* --- ssurgo Soil layer --- */
  ssurgoSoilLookups: null,
  ssurgoSoilLoading: null,
  ssurgoSoilLoadedCountries: [],
  ssurgoSoilLoadedIds: [],
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setView: (state, action) => {
      state.centerLat = action.payload.centerLat
      state.centerLon = action.payload.centerLon
      state.zoom = action.payload.zoom
      state.countryId = action.payload.countryId
    },
    toggleSelectedLayer: (state, action) => {
      if (state.selectedLayer === action.payload) {
        state.selectedLayer = null
      } else {
        state.selectedLayer = action.payload
      }
    },
    /* --- Climate --- */
    setClimateLoading: (state, action) => {
      state.climateLoading = action.payload
    },
    receiveClimateLookups: (state, action) => {
      //create the list if it doesn't exist
      state.climateLookups = state.climateLookups ?? []

      //only add the lookups that aren't already in the list
      state.climateLookups = state.climateLookups
        .concat(
          action.payload.filter(lookup => !state.climateLookups.some(s => s.id === lookup.id))
        )
        .sort()
    },
    receiveClimateLoadedCountry: (state, action) => {
      state.climateLoadedCountries.push(action.payload)
      state.climateLoadedCountries.sort()
    },
    receiveClimateLoadedIds: (state, action) => {
      state.climateLoadedIds = state.climateLoadedIds.concat(action.payload)
    },
    /* --- Basic Soil --- */
    setSoilLoading: (state, action) => {
      state.soilLoading = action.payload
    },
    receiveSoilLookups: (state, action) => {
      //create the list if it doesn't exist
      state.soilLookups = state.soilLookups ?? []

      //only add the lookups that aren't already in the list
      state.soilLookups = state.soilLookups
        .concat(
          action.payload.filter(lookup => !state.soilLookups.some(s => s.id === lookup.id))
        )
        .sort()
    },
    receiveSoilLoadedCountry: (state, action) => {
      state.soilLoadedCountries.push(action.payload)
      state.soilLoadedCountries.sort()
    },
    receiveSoilLoadedIds: (state, action) => {
      state.soilLoadedIds = state.soilLoadedIds.concat(action.payload)
    },

    /* --- Ssurgo Soil --- */
    setSsurgoSoilLoading: (state, action) => {
      state.ssurgoSoilLoading = action.payload
    },
    receiveSsurgoSoilLookups: (state, action) => {
      //create the list if it doesn't exist
      state.ssurgoSoilLookups = state.ssurgoSoilLookups ?? []

      //only add the lookups that aren't already in the list
      state.ssurgoSoilLookups = state.ssurgoSoilLookups
        .concat(
          action.payload.filter(
            lookup => !state.ssurgoSoilLookups.some(s => s.id === lookup.id)
          )
        )
        .sort()
    },
    receiveSsurgoSoilLoadedCountry: (state, action) => {
      state.ssurgoSoilLoadedCountries.push(action.payload)
      state.ssurgoSoilLoadedCountries.sort()
    },
    receiveSsurgoSoilLoadedIds: (state, action) => {
      state.ssurgoSoilLoadedIds = state.ssurgoSoilLoadedIds.concat(action.payload)
    },
  },
})

export const {
  setView,
  toggleSelectedLayer,
  /* --- Climate --- */
  setClimateLoading,
  receiveClimateLookups,
  receiveClimateLoadedCountry,
  receiveClimateLoadedIds,
  /* --- Basic Soils --- */
  setSoilLoading,
  receiveSoilLookups,
  receiveSoilLoadedCountry,
  receiveSoilLoadedIds,
  /* --- Ssurgo Soils --- */
  setSsurgoSoilLoading,
  receiveSsurgoSoilLookups,
  receiveSsurgoSoilLoadedCountry,
  receiveSsurgoSoilLoadedIds,
} = mapSlice.actions

export const mapReducer = mapSlice.reducer
