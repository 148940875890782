class RequestError {
  constructor(status, message) {
    this.status = status
    this.message = message
  }
}

const makePostOptions = data => ({
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
})

const makeGetUrl = (url, parameters) =>
  url + (parameters ? '?' + new URLSearchParams(parameters) : '')

const request = (url, options) =>
  fetch(url, options).then(response => {
    const { status } = response

    if (status === 204) {
      return {}
    }

    const json = response.json()

    if (status >= 200 && status < 400) {
      return json
    }

    return json.then(message => {
      throw new RequestError(status, message)
    })
  })

export const get = (url, parameters) => request(makeGetUrl(url, parameters), { method: 'GET' })
export const post = (url, data) => request(url, makePostOptions(data))
