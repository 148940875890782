import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import HttpsRedirect from 'react-https-redirect'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'

import { store } from './store/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import './global.css'
import './global-colors-icons.css'
import theme from './assets/theme/theme.js'

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <HttpsRedirect>
    <Provider store={store}>
      <BrowserRouter>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <App />
          </ThemeProvider>
        </CacheProvider>
      </BrowserRouter>
    </Provider>
  </HttpsRedirect>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
