import BingMaps from 'ol/source/BingMaps'
import TileLayer from 'ol/layer/Tile'
import WMTS from 'ol/source/WMTS'
import TileGrid from 'ol/tilegrid/WMTS'
import { XYZ } from 'ol/source'
import { get as getProjection } from 'ol/proj'
import { convertX, getX, isX } from './doX'

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { Icon, Style } from 'ol/style.js'
import farmIcon from './assets/fi-slategrey.png'
import { LAYER_NAMES } from './map.constants'
import { IMAGE_ATTRIBS, IMAGE_TILES } from '../../constants/api'
/**
 * Rudy-mentory constants for GEOSERVER --> OL
 */
export const resolutions = [
  156543.03390625, 78271.516953125, 39135.7584765625, 19567.87923828125, 9783.939619140625,
  4891.9698095703125, 2445.9849047851562, 1222.9924523925781, 611.4962261962891,
  305.74811309814453, 152.87405654907226, 76.43702827453613, 38.218514137268066,
  19.109257068634033, 9.554628534317017, 4.777314267158508, 2.388657133579254,
  1.194328566789627, 0.5971642833948135, 0.29858214169740677, 0.14929107084870338,
  0.07464553542435169, 0.037322767712175846, 0.018661383856087923, 0.009330691928043961,
  0.004665345964021981, 0.0023326729820109904, 0.0011663364910054952, 5.831682455027476e-4,
  2.915841227513738e-4, 1.457920613756869e-4,
]

const ESPG = 'EPSG:900913'
export const projection = getProjection(ESPG)

const tileGrid = new TileGrid({
  tileSize: [256, 256],
  extent: [-20037508.34, -20037508.34, 20037508.34, 20037508.34],
  origin: [-20037508.34, 20037508.34],
  resolutions,
  matrixIds: resolutions.map((_, i) => `${ESPG}:${i}`),
})

/**
 * Layer Constants
 */

export const TOP_LAYERS_PROPS = [
  {
    layer: LAYER_NAMES.TERRITORY.layer,
    id: 'unterries',
    opacity: 1,
    title: 'Outline',
    name: 'Territories',
  },
]

export const TOP_LAYERS = TOP_LAYERS_PROPS.map(d => createTileLayerWMTS(d))

const BASE_LAYERS_PROTO_PROPS = [
  {
    id: 'satellite',
    name: 'Satellite',
    title: 'Satellite',
    className: 'dark-layer',
    tileLayerProps: {
      visible: true,
      preload: Infinity,
      source: new BingMaps({
        key: process.env.REACT_APP_BING_MAP_KEY,
        imagerySet: 'AerialWithLabels',
        maxZoom: 19,
      }),
    },
  },
  {
    id: 'street',
    name: 'Street',
    title: 'Street',
    className: '',
    tileLayerProps: {
      visible: false,
      source: new XYZ({
        url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png', // Base OSM layer
      }),
    },
  },
  {
    id: 'blank',
    name: 'Blank',
    title: 'No Background Map',
  },
]

export const BASE_LAYERS_PROPS = BASE_LAYERS_PROTO_PROPS.map(bl => {
  return {
    ...bl,
    layers: BASE_LAYERS_PROTO_PROPS.reduce(
      (acc, bbl) => ({
        ...acc,
        [bbl.name]: bbl.name === bl.name,
      }),
      {}
    ),
  }
})

export const BASE_LAYERS = BASE_LAYERS_PROPS.map(
  bl => new TileLayer({ ...bl.tileLayerProps, properties: { name: bl.name } })
)

/**
 * Layers for SCIENCE, SCIence, science!!!
 */
export const CALCULATION_LAYERS_PROPS = [
  {
    ...LAYER_NAMES.CLIMATE,
    hideKeys: false,
    valueKey: 'ipcc_desc',
    opacity: 0.6,
    idKey: 'climate_id',
    isNoData: d => [66666, undefined].includes(d),
    attributes: ['TERR_NAME', 'climate_id', 'ipcc_desc'],
    getId: id => id,
  },
  {
    ...LAYER_NAMES.SOIL_GLOBAL,
    hideKeys: false,
    valueKey: 'ipcc_name',
    opacity: 0.6,
    idKey: 'soil_id',
    isNoData: d => [66666, undefined].includes(d),
    attributes: ['soil_id', 'ipcc_name'],
    getId: id => id,
  },
  {
    ...LAYER_NAMES.SOIL_NATIONAL,
    hideKeys: false,
    valueKey: 'ipcc_name',
    opacity: 0.6,
    idKey: 'soil_id',
    isNoData: d => [66666, undefined].includes(d),
    attributes: ['soil_id', 'ipcc_name'],
    getId: id => id,
  },
  {
    ...LAYER_NAMES.TERRITORY,
    hideKeys: false,
    hideUi: true,
    valueKey: 'lsib_name',
    opacity: 1,
    idKey: 'terr_id',
    isNoData: d => [].includes(d),
    attributes: ['terr_id', 'lsib_name'],
    getId: id => id,
  },
  {
    ...LAYER_NAMES.USALAYER,
    hideKeys: false,
    hideUi: true,
    visible: true,
    valueKey: 'TERR_ID',
    opacity: 0.5,
    idKey: 'TERR_ID',
    isNoData: d => [].includes(d),
    attributes: ['TERR_ID', 'TERR_NAME'],
    getId: id => id,
  },
]

export const TOGGLEABLE_LAYERS_PROPS = CALCULATION_LAYERS_PROPS.filter(e => !e.hideUi)

export const TOGGLEABLE_LAYERS = TOGGLEABLE_LAYERS_PROPS.map(({ label, ...d }) =>
  createTileLayerWMTS({ ...d, name: label.plural })
)

/**
 * MAP FUNCTIONS
 */
export function toggleMapLayer(map, layerName) {
  if (!map) return
  map.getLayers().forEach(layer => {
    if (layer.get('name') === layerName) {
      try {
        const isVis = layer.getVisible()
        layer?.setVisible(!isVis)
      } catch (e) {}
    }
  })
}

export function onChangeToggleLayer(map, visibility, layerName) {
  if (!map) return
  let newVis = null
  map.getLayers().forEach(layer => {
    if (layer.get('name') === layerName) {
      newVis = !layer.getVisible()
      layer.setVisible(isX.isBoolean(visibility) ? visibility : newVis)
    }
  })
  return newVis
}

export function adjustMapOpacity(map, opacity, layerName) {
  if (!map) return
  map.getLayers().forEach(layer => {
    if (layer.get('name') === layerName) {
      layer.setOpacity(+opacity / 100)
    }
  })
}

export const getLayerSupplementaryElementClassName = layerName =>
  `map-layer-supplementary-element-${convertX.txt2LowerNoSpaces(layerName)}`

export const getLayerMapKeyElementClassName = layerName =>
  `map_key_${convertX.txt2LowerNoSpaces(layerName)}`

export async function fetchLayerAttributesOnClickAsync(event, callBack) {
  const [lon, lat] = event.coordinate // Get clicked coordinates
  const data = await Promise.all(
    CALCULATION_LAYERS_PROPS.map(
      async ({ layer, valueKey, isNoData, idKey, attributes, getId, ...rest }) => {
        // prettier-ignore
        const url = `${IMAGE_ATTRIBS}?layer=${layer}&lon=${lon}&lat=${lat}&propertyName=${attributes.join(",")}`

        try {
          const response = await fetch(url)
          const data = await response.json()
          const { features } = data
          const id = getX.value(features, 0, 'properties', idKey)
          const transdata = {
            ...rest,
            layer,
            features,
            id: getId(id),
            value: getX.value(features, 0, 'properties', valueKey),
            properties: getX.value(features, 0, 'properties'),
            isNoData: isNoData(id),
          }
          const returnData = isX.isFunct(callBack) && (await callBack(transdata))
          return returnData || transdata
        } catch (error) {
          console.error(`Error fetching WFS data: ${layer}`, error)
          return null // Return null or handle the error smartly...
        }
      }
    )
  )
  return data
}

export function handlePlaceMarker(event, vectorSource) {
  const coordinate = event.coordinate

  /* -- Clear the existing features (i.e., marker) in the vector source --*/
  vectorSource.clear()

  /* -- Create a new feature (marker) with the clicked coordinate -- */
  const iconFeature = new Feature({
    geometry: new Point(coordinate),
  })

  /* -- Define the style for the marker (using an icon image) -- */
  const iconStyle = new Style({
    image: new Icon({
      anchor: [0.5, 1], // Set the anchor to the bottom of the icon
      src: farmIcon,
    }),
  })

  iconFeature.setStyle(iconStyle)
  vectorSource.addFeature(iconFeature)
}

export const centerOnLastAddedPoint = (map, coordinate) => {
  if (map && coordinate) {
    const view = map.getView()
    view.setCenter(coordinate)
    view.setZoom(8)
  }
}

function createTileLayerWMTS_noProxy({ layer, name, opacity }) {
  return new TileLayer({
    visible: false,
    opacity: opacity || 1,
    source: new WMTS({
      url: process.env.REACT_APP_GEOSERVER_URL,
      layer,
      matrixSet: ESPG,
      format: 'image/png',
      projection,
      tileGrid,
      style: '',
      wrapX: true,
    }),
    properties: { name },
  })
}

function createTileLayerWMTS({ visible, layer, name, opacity }) {
  return new TileLayer({
    visible: visible || false,
    opacity: opacity || 1,
    source: new XYZ({
      url: `${IMAGE_TILES}/${layer}/{z}/{x}/{y}.png`,
      projection,
      wrapX: true,
    }),
    properties: { name },
  })
}
