import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  agroforestryList: [],
  selectedIds: [],
  results: [],
}

export const conservationPracticeSlice = createSlice({
  name: 'conservationPractice',
  initialState: initialState,
  reducers: {
    receiveConservationPracticeList: (state, action) => {
      state.list = action.payload
    },
    receiveAgroforestryPracticeList: (state, action) => {
      state.agroforestryList = action.payload
    },
    selectConservationPractice: (state, action) => {
      state.selectedIds.push(action.payload)
    },
    deselectConservationPractice: (state, action) => {
      const currentIndex = state.selectedIds.indexOf(action.payload)
      state.selectedIds.splice(currentIndex, 1)
    },
    receiveResult: (state, action) => {
      const result = state.results.find(r => r.id === action.payload.id)
      if (result) {
        result.name = action.payload.name
        result.error = action.payload.error
        result.soilC = action.payload.soilC
        result.biomassC = action.payload.biomassC
        result.baselineInputClass = action.payload.baselineInputClass
        result.baselineManagementClass = action.payload.baselineManagementClass
        result.scenarioInputClass = action.payload.scenarioInputClass
        result.scenarioManagementClass = action.payload.scenarioManagementClass
      } else {
        state.results.push(action.payload)
      }
    },
    updateAreaInput: (state, action) => {
      const result = state.results.find(r => r.id === action.payload.id)
      if (result) result.area = action.payload.area
    },
    clearSelectedPractices: (state, action) => {
      const newSelected = state.selectedIds.filter(s => !action.payload.includes(s))
      state.selectedIds = newSelected
      const newResults = state.results.filter(r => !action.payload.includes(r.id))
      state.results = newResults
    },
    clearCoefficients: state => {
      for (var result of state.results) {
        result.error = null
        result.soilC = 0
        result.biomassC = 0
      }
    },
  },
})

export const {
  receiveConservationPracticeList,
  receiveAgroforestryPracticeList,
  selectConservationPractice,
  deselectConservationPractice,
  receiveResult,
  clearSelectedPractices,
  updateAreaInput,
  clearCoefficients,
} = conservationPracticeSlice.actions

export const conservationPracticeReducer = conservationPracticeSlice.reducer
