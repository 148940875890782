// TODO: Need to implement saga methods here, the included methods are what I expect we'll need

import { call, select, put, all } from 'redux-saga/effects'
import {
  getAnimalTypesList,
  getAnimalTypesSelectedIds,
  getLivestockResults,
  getLocation,
} from '../store/selectors'
import {
  clearLivestockResults,
  receiveAnimalTypesList,
  receiveManureStorageList,
  receiveResult,
} from '../reducers/livestock'
import { get, post } from '../utils/api'
import {
  LIST_LIVESTOCK_MANURE,
  LIST_LIVESTOCK_SPECIES,
  LIVESTOCK_RESULTS,
} from '../constants/api'

export function* listAnimalTypes() {
  try {
    const data = yield call(get, LIST_LIVESTOCK_SPECIES)
    yield put(receiveAnimalTypesList(data))
  } catch (error) {
    console.warn('Error listing livestock species: ', error)
  }
}

export function* listManureStorageTypes() {
  try {
    const selectedAnimals = yield select(getAnimalTypesSelectedIds)
    const data = yield call(post, LIST_LIVESTOCK_MANURE, selectedAnimals)
    yield put(receiveManureStorageList(data))
  } catch (error) {
    console.warn('Error listing livestock manure systems: ', error)
  }
}

// Gets animal lookup info from ID
function* getSelectedAnimal(speciesId) {
  const animalTypesList = yield select(getAnimalTypesList)
  const animalType = animalTypesList.find(at => at.id === speciesId)
  return animalType
}
export function* selectAnimalType(action) {
  const animalType = yield getSelectedAnimal(action.payload)
  yield call(getResults, animalType)
}
// I'm not sure if there is a way to make the next four sagas into one method...
export function* updateBaselineProductionSystem(action) {
  const animalType = yield getSelectedAnimal(action.payload.id)
  yield call(getResults, animalType, true)
}

export function* updateScenarioProductionSystem(action) {
  const animalType = yield getSelectedAnimal(action.payload.id)
  yield call(getResults, animalType, false)
}

export function* updateBaselineManureStorage(action) {
  const animalType = yield getSelectedAnimal(action.payload.id)
  yield call(getResults, animalType, true)
}

export function* updateScenarioManureStorage(action) {
  const animalType = yield getSelectedAnimal(action.payload.id)
  yield call(getResults, animalType, false)
}

export function* recalculateSelectedAnimalTypes() {
  const location = yield select(getLocation)

  if (location.climate && location.soil) {
    const animalTypesList = yield select(getAnimalTypesList)
    const selectedIds = yield select(getAnimalTypesSelectedIds)

    // Add all previously selected livestock species
    const selectedAnimalTypes = animalTypesList.filter(c => selectedIds.includes(c.id))

    yield all(
      selectedAnimalTypes.map(c => {
        call(getResults, c)
        call(getResults, c, false) // GM 12/13, need to also recalculate scenario values
      })
    )
  }
}

function* getResults(animalType, isBaseline = true) {
  const location = yield select(getLocation) // Selected location

  const livestockResults = yield select(getLivestockResults)
  const livestockResult = livestockResults.find(r => r.id === animalType.id)

  //if updated scenario doesn't have productivity and manure don't run
  if (
    (isBaseline &&
      livestockResult?.baselineProductionSystem &&
      livestockResult?.baselineManureStorage) ||
    (!isBaseline && livestockResult?.scenarioManureStorage)
  ) {
    const payload = {
      climate: location.climate,
      country: { ID: location.countryId },
      livestockScenario: {
        speciesLookup: animalType,
        productionSystem: {
          ID: isBaseline
            ? livestockResult.baselineProductionSystem
            : livestockResult.scenarioProductionSystem,
        },
        manureStorage: isBaseline
          ? livestockResult.baselineManureStorage
          : livestockResult.scenarioManureStorage,
      },
    }

    try {
      const results = yield call(post, LIVESTOCK_RESULTS, payload)
      results.isBaseline = isBaseline
      results.id = animalType.id
      yield put(receiveResult(results))
    } catch ({ status, message }) {}
  } else {
    yield put(
      receiveResult({
        id: animalType.id,
        name: animalType.name,
        category: 'livestock',
        baselinePopulation: 0,
        scenarioPopulation: null,
        baselineProductionSystem: null, // Baseline production system is currently as LookupId
        scenarioProductionSystem: null, // Same as baseline
        baselineManureStorage: null,
        scenarioManureStorage: null,
        baselineCH4Enteric: null,
        baselineCH4Manure: null,
        baselineN2ODirect: null,
        baselineN2OIndirectL: null,
        baselineN2OIndirectV: null,
        scenarioCH4Enteric: null,
        scenarioCH4Manure: null,
        scenarioN2ODirect: null,
        scenarioN2OIndirectL: null,
        scenarioN2OIndirectV: null,
        changeCH4: null,
        changeCH4Enteric: null,
        changeCH4Manure: null,
        changeN2O: null,
        changeN2ODirect: null,
        changeN2OIndirectL: null,
        changeN2OIndirectV: null,
        changeTotal: null,
      })
    )
  }
}
