import { IMAGE_STYLES } from '../../constants/api.js'
import { isX } from './doX.js'

// async function getLayerStylesBaseFunction(name, geoserverLayer) {
export async function getLayerStyles(name, geoserverLayer) {
  let layers = 'ssurgo_puertorico%3Apr_ssurgo_ipcc1_3857'
  if (isX.isNonEmptyArray(geoserverLayer)) layers = geoserverLayer.join(',')
  if (isX.isString(geoserverLayer)) layers = geoserverLayer
  // .replace(/:/g, "%3") // saving not sure if necessary in some scenarios
  const url = `${IMAGE_STYLES}?layers=${layers}`

  return fetch(url)
    .then(response => response.text()) // turning xml to text
    .then(data => {
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(data, 'text/xml')

      const colorNames = xmlDoc.getElementsByTagName('sld:Title')
      const colorHexes = xmlDoc.querySelectorAll('[name="fill"]')
      if (colorNames.length !== colorHexes.length) throw Error
      const fillElements = []
      for (let i = 0; i < colorNames.length; i++) {
        fillElements.push({ name: colorNames[i].textContent, fill: colorHexes[i].textContent })
      }
      return { name, fillElements }
    })
    .catch(error => console.error('Error fetching the styles XML:', error))
}

export function fetchAttributesCsvByLayerNameAttribs(layer, attributes, callBack) {
  const wfsUrl = `${
    process.env.REACT_APP_GEOSERVER_BASE_URL
  }/wfs?service=WFS&version=1.1.0&request=GetFeature&propertyName=${attributes.join(
    ','
  )}&typeName=${layer}&outputFormat=csv&srsname=EPSG:3857`

  return fetch(wfsUrl)
    .then(response => response.text()) // Read response as text since it's CSV
    .then(data => {
      // Create a blob from the CSV data
      const blob = new Blob([data], { type: 'text/csv' })

      // Create a download link and trigger it
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${layer}_attributes.csv` // Name of the downloaded file
      document.body.appendChild(a)
      a.click()

      // Clean up
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)

      // Optional: call the callback function if provided
      if (typeof callBack === 'function') {
        callBack(data) // You could send CSV data to the callback if needed
      }
    })
    .catch(error => console.error(`Error fetching WFS data: ${layer}`, error))
}
