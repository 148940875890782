import { createSlice } from '@reduxjs/toolkit'

// Is it good practice to have a non-null initial value?
// This is so components that are valid for all land uses appear before the user makes a selection
const initialState = {
  list: [],
  selectedId: 0,
  farmTypeId: null,
}

export const baselineSlice = createSlice({
  name: 'baseline',
  initialState: initialState,
  reducers: {
    receiveBaselineList: (state, action) => {
      state.list = action.payload
    },
    setBaseline: (state, action) => {
      state.selectedId = action.payload
    },
    setFarmType: (state, action) => {
      state.farmTypeId = action.payload
    },
  },
})

export const { receiveBaselineList, setBaseline, setFarmType } = baselineSlice.actions

export const baselineReducer = baselineSlice.reducer
