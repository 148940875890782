import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  animalTypes: [],
  manureStorage: [],
  selectedIds: [],
  results: [],
}

const calculateResults = (result, baselineValue, scenarioValue) => {
  let baselineResult = 0
  let scenarioResult = 0
  if (baselineValue && baselineValue === '--') return '--'
  if (scenarioValue && scenarioValue === '--') return '--'
  scenarioResult = result.scenarioPopulation * scenarioValue
  baselineResult = result.baselinePopulation * baselineValue
  return baselineResult - scenarioResult
}

const cleanseTotal = value => {
  if (value === '--') return 0
  else return value
}

const addResults = valueArray => {
  let result = 0
  valueArray.forEach(value => {
    if (value === '--') result = '--'
    else if (result !== '--') result += value
  })
  return result
}

const hasCompleteData = result => {
  return (
    result.baselineManureStorage &&
    result.baselineProductionSystem &&
    result.scenarioManureStorage &&
    result.scenarioProductionSystem
  )
}

const calculateResultsTotal = result => {
  if (hasCompleteData(result)) {
    result.changeCH4Enteric = calculateResults(
      result,
      result.baselineCH4Enteric,
      result.scenarioCH4Enteric
    )
    result.changeCH4Manure = calculateResults(
      result,
      result.baselineCH4Manure,
      result.scenarioCH4Manure
    )
    result.changeCH4 = addResults([result.changeCH4Enteric, result.changeCH4Manure])
    result.changeN2ODirect = calculateResults(
      result,
      result.baselineN2ODirect,
      result.scenarioN2ODirect
    )
    result.changeN2OIndirectL = calculateResults(
      result,
      result.baselineN2OIndirectL,
      result.scenarioN2OIndirectL
    )
    result.changeN2OIndirectV = calculateResults(
      result,
      result.baselineN2OIndirectV,
      result.scenarioN2OIndirectV
    )
    result.changeN2O = addResults([
      result.changeN2ODirect,
      result.changeN2OIndirectL,
      result.changeN2OIndirectV,
    ])

    result.changeTotal =
      cleanseTotal(result.changeCH4Enteric) +
      cleanseTotal(result.changeCH4Manure) +
      cleanseTotal(result.changeN2ODirect) +
      cleanseTotal(result.changeN2OIndirectL) +
      cleanseTotal(result.changeN2OIndirectV)
  }
}

export const livestockSlice = createSlice({
  name: 'livestock',
  initialState: initialState,
  reducers: {
    receiveAnimalTypesList: (state, action) => {
      state.animalTypes = action.payload
    },
    receiveManureStorageList: (state, action) => {
      state.manureStorage = action.payload
    },
    selectAnimalType: (state, action) => {
      state.selectedIds.push(action.payload)
    },
    deselectAnimalType: (state, action) => {
      const animalIndex = state.selectedIds.indexOf(action.payload)

      // had to do two steps here as the indexOf((r) => r.id === action.payload) was returning -1
      const result = state.results.find(r => r.id === action.payload)
      const resultsIndex = state.results.indexOf(result)

      state.selectedIds.splice(animalIndex, 1)
      state.results.splice(resultsIndex, 1)
    },
    clearSelectedAnimalTypes: state => {
      state.selectedIds = []
    },
    updateBaselinePopulation: (state, action) => {
      const result = state.results.find(r => r.id === action.payload.id)
      if (result) {
        result.baselinePopulation = action.payload.population
        calculateResultsTotal(result)
      }
    },
    updateScenarioPopulation: (state, action) => {
      const result = state.results.find(r => r.id === action.payload.id)
      if (result) {
        result.scenarioPopulation = action.payload.population
        calculateResultsTotal(result)
      }
    },
    updateBaselineProductionSystem: (state, action) => {
      const result = state.results.find(r => r.id === action.payload.id)
      if (result) result.baselineProductionSystem = action.payload.productionSystem
    },
    updateScenarioProductionSystem: (state, action) => {
      const result = state.results.find(r => r.id === action.payload.id)
      if (result) result.scenarioProductionSystem = action.payload.productionSystem
    },
    updateBaselineManureStorage: (state, action) => {
      const result = state.results.find(r => r.id === action.payload.id)
      if (result) result.baselineManureStorage = action.payload.manureStorage
    },
    updateScenarioManureStorage: (state, action) => {
      const result = state.results.find(r => r.id === action.payload.id)
      if (result) result.scenarioManureStorage = action.payload.manureStorage
    },
    clearLivestockResults: state => {
      state.selectedIds = []
      state.results = []
    },
    receiveResult: (state, action) => {
      const result = state.results.find(r => r.id === action.payload.id)
      const resultIn = action.payload
      if (result) {
        if (resultIn.isBaseline) {
          result.baselineCH4Enteric = resultIn.ch4_enteric
          result.baselineCH4Manure = resultIn.ch4_manure
          result.baselineN2ODirect = resultIn.n2o_direct
          result.baselineN2OIndirectL = resultIn.n2o_indirect_l
          result.baselineN2OIndirectV = resultIn.n2o_indirect_v
        } else if (resultIn.isBaseline === false) {
          result.scenarioCH4Enteric = resultIn.ch4_enteric
          result.scenarioCH4Manure = resultIn.ch4_manure
          result.scenarioN2ODirect = resultIn.n2o_direct
          result.scenarioN2OIndirectL = resultIn.n2o_indirect_l
          result.scenarioN2OIndirectV = resultIn.n2o_indirect_v
        }

        calculateResultsTotal(result)
      } else {
        //this should only happen when an animal is selected
        state.results.push(action.payload)
      }
    },
  },
})

export const {
  receiveAnimalTypesList,
  receiveManureStorageList,
  selectAnimalType,
  deselectAnimalType,
  clearSelectedAnimalTypes,
  updateBaselinePopulation,
  updateScenarioPopulation,
  updateBaselineProductionSystem,
  updateScenarioProductionSystem,
  updateBaselineManureStorage,
  updateScenarioManureStorage,
  clearLivestockResults,
  receiveResult,
} = livestockSlice.actions

export const livestockReducer = livestockSlice.reducer
