//@ts-nocheck
'use client'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Map as OlMap, View } from 'ol'
import { fromLonLat } from 'ol/proj'
import { defaults as defaultControls, ScaleLine, ZoomSlider } from 'ol/control'
import 'ol/ol.css'
import LocationSearchBar from './map.components/LocationSearchbar'
import { IconButton } from './map.components/map.ui.tsx'
import { getLayerStyles } from './map.fetches'
import {
  TOGGLEABLE_LAYERS,
  CALCULATION_LAYERS_PROPS,
  resolutions,
  projection,
  BASE_LAYERS,
  TOGGLEABLE_LAYERS_PROPS,
  fetchLayerAttributesOnClickAsync,
  handlePlaceMarker,
  centerOnLastAddedPoint,
  TOP_LAYERS,
} from './map.utils'
import './map.css'
import { getX } from './doX'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'

import { MapLayerBasics } from './map.typedefs'
import CloseIcon from '@mui/icons-material/Close'
import { setLocation } from '../../reducers/location.js'
import Dialog from '../ui/Dialog.jsx'
// @ts-ignore
import BackgroundLayers from './map.components/BackgroundLayers.jsx'
import { ZoomOut100, CenterMe, MapIcon } from './map.assets/svgs'
import {
  MapInfoPopup,
  ScienceLayers,
  MapKeys,
  ContinentalUSPopup,
} from './map.components/index.tsx'
import { LAYER_NAMES } from './map.constants.jsx'

const Map = () => {
  const mapRef = useRef(null)
  const [map, setMap] = useState<OlMap | null>(null)
  const [coordOfPlaceMarker, setCoordOfPlaceMarker] = useState<any>(null)
  const [colorKey, setColorKey] = useState<any>([])
  const [dismissPlannerDomesticCallToAction, setDismissPlannerDomesticCallToAction] =
    useState(false)
  const [isLoadingMapAttributes, setIsLoadingMapAttributes] = useState(false)
  // prettier-ignore
  const [mapAttributes, setMapAttributes] = useState<MapLayerBasics[]>(CALCULATION_LAYERS_PROPS)
  const dispatch = useDispatch()
  const placeMarkerSource = new VectorSource()

  useEffect(() => {
    ;(async () => {
      let fetchedLayerStyles = []

      for (const layer of TOGGLEABLE_LAYERS_PROPS) {
        if (!layer.hideKeys) {
          const layerStyles = await getLayerStyles(layer.label.plural, layer.layer)
          // @ts-ignore
          fetchedLayerStyles.push(layerStyles)
        }
      }

      setColorKey(fetchedLayerStyles)
    })()

    const createdMap: OlMap = new OlMap({
      target: mapRef.current || '',
      layers: [
        ...BASE_LAYERS,
        ...TOGGLEABLE_LAYERS,
        ...TOP_LAYERS,
        new VectorLayer({
          source: placeMarkerSource,
          properties: { name: 'PlaceMarker' },
          zIndex: 1000, // on top of all layers
        }),
      ],

      view: new View({
        center: fromLonLat([0, 0]),
        zoom: 2,
        projection: projection || '',
        resolutions,
      }),
      controls: defaultControls().extend([new ScaleLine(), new ZoomSlider()]),
    })

    createdMap
      .getView()
      .fit([-20037508.34, -8351748.480224858, 20037508.34, 19008175.684346247], {
        size: createdMap.getSize(),
      })
    createdMap.on('singleclick', async event => {
      handlePlaceMarker(event, placeMarkerSource)
      setCoordOfPlaceMarker(event.coordinate)
      /**
       * TODO: this could be considered very dumb in some sirkles.
       */
      const rawAttribs = await fetchLayerAttributesOnClickAsync(event, function (data: any) {
        setMapAttributes(prev => {
          if (prev.length === 0) return [data]
          if (prev.findIndex(d => d.layer === data.layer) < 0) return [...prev, data]
          return prev.map(p => (p.layer === data.layer ? data : p))
        })
      })

      /**
       * @notes are jsx elements and should not be passed to dispatch
       */

      dispatch(
        setLocation({
          coord: event.coordinate,
          attribs: rawAttribs.map(({ notes, ...attribs }) => attribs),
        })
      )
    })

    setMap(createdMap)

    // @ts-ignore
    return () => {
      /* === Remove layers, interactions, overlays === */
      createdMap.getLayers().clear()
      createdMap.getInteractions().clear()
      createdMap.getOverlays().clear()

      // @ts-ignore
      createdMap.setTarget(null) // Unset the target map
    }
  }, [])

  return (
    <div className="map-container h-full w-full relative" style={{ height: '600px' }}>
      <div ref={mapRef} style={{ width: '100%', height: '100%' }} />

      <div className={'map-upper-left-controls'}>
        <LocationSearchBar map={map} />
        <IconButton
          onClick={() => map?.getView().setZoom(0)}
          title="Zoom Out"
          children={<ZoomOut100 />}
          className="map-recenter-icon map-transition"
          classNameWrap="hidden md-flex"
        />
        <IconButton
          onClick={() => centerOnLastAddedPoint(map, coordOfPlaceMarker)}
          title="Center on Farm"
          children={<CenterMe />}
          className="map-recenter-icon map-transition"
          classNameWrap="hidden md-flex"
          style={{
            opacity: coordOfPlaceMarker ? 1 : 0,
            pointerEvents: coordOfPlaceMarker ? 'unset' : 'none',
          }}
        />
      </div>

      <MapInfoPopup state={mapAttributes} />

      <div className="map-upper-right-controls pointer-events-none">
        <ScienceLayers className="map-interaction-card pointer-events-auto" map={map} />
        <div>
          <MapKeys colorKey={colorKey} />
        </div>
      </div>

      <BackgroundLayers map={map} />
      {/* @ts-ignore */}
      <Dialog
        isOpen={
          !dismissPlannerDomesticCallToAction &&
          (() => {
            const mapTributes = getX
              .array(mapAttributes)
              .find((a: MapLayerBasics) => a?.group === LAYER_NAMES.USALAYER.group)
            return !!mapTributes?.id
          })()
        }
        onClose={() => setDismissPlannerDomesticCallToAction(true)}
        header={'United States of America'}
        disableFooter
      >
        <ContinentalUSPopup close={() => setDismissPlannerDomesticCallToAction(true)} />
      </Dialog>
    </div>
  )
}

export default Map
