const BACKEND = process.env.REACT_APP_BACKEND_URL
// const BACKEND = process.env.REACT_APP_CORS_TESTING_PREFIX;

export const LIST_BASELINE = `${BACKEND}/home/listBaseline`

export const LIST_CONSERVATION_PRACTICES = `${BACKEND}/home/listConservationPractices`

export const LIST_AGROFORESTRY_PRACTICES = `${BACKEND}/home/listAgroforestryPractices`

export const LIST_LIVESTOCK_SPECIES = `${BACKEND}/home/listLivestockSpecies`

export const LIST_LIVESTOCK_MANURE = `${BACKEND}/home/listLivestockManure`

export const LOCATION_DATA = `${BACKEND}/home/locationData`

export const RESULTS = `${BACKEND}/home/results`

export const LIVESTOCK_RESULTS = `${BACKEND}/home/livestockResults`

export const CLIMATE_LAYER = `${BACKEND}/home/climateLayer`

export const SOILS_LAYER = `${BACKEND}/home/soilsLayer`

export const SSURGO_SOILS_LAYER = `${BACKEND}/home/soilSsurgoLayer`

export const COUNTRIES_LAYER = `${BACKEND}/home/countriesLayer`

export const USA_LAYER = `${BACKEND}/home/usaLayer`

export const CONTACT_US = `${BACKEND}/service/contactus`

export const IMAGE_TILES = `${BACKEND}/image/wmts`

export const IMAGE_ATTRIBS = `${BACKEND}/image/attribs`

export const IMAGE_STYLES = `${BACKEND}/image/styles`
