import React from 'react'
import { useSelector } from 'react-redux'
import { Card, Link, Fab } from '@mui/material'
import Dialog from '../ui/Dialog.jsx'
import ConservationPracticeCard from '../Cards/ConservationPracticeCard.jsx'
import { getBaselineSelectedId } from '../../store/selectors.js'
import ResultsCard from '../Cards/ResultsCard/ResultsCard.jsx'
import { CarbonSequestrationKey } from '../Cards/ResultsCard/CarbonSequestrationKey.jsx'
import './Dashboard.css'
import MapWrapper from '../../components/MapGeoserver/Map.tsx'
import StepTitle from '../Cards/StepTitle.jsx'
import LandUse from '../Cards/LandUse/index.jsx'
import {
  LAND_USE_CONSERVATION,
  STEP_TITLES as STEP,
  TWENTY_YR_EXPLANATION,
} from '../../constants'
import FarmType from '../Cards/FarmType.jsx'
import AnimalType from '../Cards/Livestock/AnimalType.jsx'
import AnimalBaselineManagement from '../Cards/Livestock/AnimalBaselineManagement.jsx'
import AnimalConservationManagement from '../Cards/Livestock/AnimalConservationManagement.jsx'
import {
  BaselineLandUseInfo,
  ConservationPractInfo,
  FarmLocationInfo,
  FarmTypeInfo,
  AnimalTypeInfo,
  AnimalBaselineManagementInfo,
  AnimalConservationManagementInfo,
  ResultsCardInfo,
  AnimalResultsCardInfo,
} from '../Information/index.jsx'

function Dashboard() {
  const baselineLandUseId = useSelector(getBaselineSelectedId)
  const stepComponentsArray = [
    {
      title: STEP.landLocation.title,
      subTitle: STEP.landLocation.subTitle,
      info: <FarmLocationInfo />,
      content: <MapWrapper />,
      landUse: STEP.landLocation.landUse,
      stepNumber: 1,
    },
    {
      title: STEP.farmType.title,
      content: <FarmType />,
      info: <FarmTypeInfo />,
      landUse: STEP.farmType.landUse,
      stepNumber: 2,
    },
    {
      title: STEP.baselineLandUse.title,
      content: <LandUse />,
      info: <BaselineLandUseInfo />,
      landUse: STEP.baselineLandUse.landUse,
      stepNumber: 3,
    },
    {
      title: STEP.conservationPractice.title,
      content: <ConservationPracticeCard />,
      info: <ConservationPractInfo />,
      landUse: STEP.conservationPractice.landUse,
      stepNumber: 4,
    },
    {
      title: STEP.animalType.title,
      subheader:
        'Please use the checkboxes below to select any livestock species to include in the assessment.',
      content: <AnimalType />,
      info: <AnimalTypeInfo />,
      landUse: STEP.animalType.landUse,
      stepNumber: 4,
    },
    {
      title: STEP.animalBaselineManagement.title,
      subheader:
        'Next to each livestock species, please enter the average number of animals on the farm for the reference year and then select the production system and manure storage that best represent the operation.',
      content: <AnimalBaselineManagement />,
      info: <AnimalBaselineManagementInfo />,
      landUse: STEP.animalBaselineManagement.landUse,
      stepNumber: 5,
    },
    {
      title: STEP.animalConservationManagement.title,
      subheader: `Next to each livestock species, please enter the "new" scenario management, taking into account any changes in population, production, or manure storage.`,
      content: <AnimalConservationManagement />,
      info: <AnimalConservationManagementInfo />,
      landUse: STEP.animalConservationManagement.landUse,
      stepNumber: 6,
    },
    {
      title: STEP.ghgReduction.title,
      content: <ResultsCard />,
      info: <ResultsCardInfo />,
      landUse: STEP.ghgReduction.landUse,
      stepNumber: LAND_USE_CONSERVATION.includes(baselineLandUseId) ? 5 : 7,
      footer: (
        <div
          className="results-20yr-explanation"
          style={{ fontSize: '.95em', fontWeight: '600', margin: 'auto' }}
        >
          <div style={{ fontSize: '.8rem' }}>{TWENTY_YR_EXPLANATION}</div>
          <div style={{ margin: 'auto', marginTop: '1em' }}>
            <CarbonSequestrationKey />
          </div>
        </div>
      ),
    },
    {
      title: STEP.livestockReduction.title,
      content: <ResultsCard />,
      info: <AnimalResultsCardInfo />,
      paperProps: {
        sx: {
          width: '100%',
          maxWidth: '1000px!important',
        },
      },
      landUse: STEP.livestockReduction.landUse,
      stepNumber: 7,
      footer: (
        <div
          className="results-20yr-explanation"
          style={{ fontSize: '.95em', fontWeight: '600', margin: 'auto' }}
        >
          <div style={{ margin: 'auto', marginTop: '1em' }}>
            <CarbonSequestrationKey isLivestock={true} showNotEstimated={true} />
          </div>
        </div>
      ),
    },
  ]

  return (
    <main className="dashboard-step-wrap">
      {stepComponentsArray.map((e, i) => {
        // Check to make sure this card is valid for the selected baseline land use
        if (e.landUse.includes(baselineLandUseId)) {
          return (
            <Card className={`dashboard-card ${e.className}`} key={`dashboard_step_card_${i}`}>
              {e.title && (
                <StepTitle {...e} stepNumber={e.stepNumber} style={e?.style?.steptitle} />
              )}
              {e.content}
              {e.footer && <div className="dashboard-card-footer">{e.footer}</div>}
            </Card>
          )
        } else return null
      })}
      {/*       <Fab
        variant="extended"
        size="medium"
        sx={{
          position: "fixed",
          zIndex: 99,
          bottom: 10,
          right: 10,
          backgroundColor: "var(--color-1)",
          color: "black",
        }}
      >
        <ContactSupport sx={{ mr: 1 }} />
        Help
      </Fab> */}
    </main>
  )
}

export default Dashboard
