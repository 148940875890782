import React from 'react'
import { getValue, getObject, getCellClassNames, num2numWithCommas } from './basictable.utils'
import './BasicTable.css'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

/**
 * BasicTable combines BasicTable elements into a table component with custom styling
 * @param {*} props can consist of a caption, header values, row values, footer values, a className, styling, and an array of column keys (tableKeys)
 * @returns an MUI TableContainer component with header, body, and footer children
 */
export default function BasicTable(props) {
  const { caption, tableHeader, tableRows, tableFooter, tableKeys, ...rest } = props
  if (!(Array.isArray(tableKeys) && tableKeys.length > 0)) return
  return (
    <TableContainer {...rest}>
      <Table>
        {caption && <Typography variant="caption">{caption}</Typography>}
        {tableHeader && <BasicTableHeader {...{ tableKeys, rowData: tableHeader }} />}
        {Array.isArray(tableRows) && (
          <BasicTableBody {...{ tableKeys, rowsData: tableRows, tableFooter }} />
        )}
        {tableFooter && <BasicTableFooter {...{ tableKeys, rowData: tableFooter }} />}
      </Table>
    </TableContainer>
  )
}

/**
 * BasicTableHeader renders an array of header objects into cells for display
 * @param {*} props expects two values
 * tableKeys should be an array of "key" values for each column
 * rowData should be an array of key-value objects with ids matching values in the key list
 * @returns an MUI TableHead component with cells for each matching rowData/key pair
 */
const BasicTableHeader = props => {
  const { rowData, tableKeys } = props
  return (
    <TableHead>
      <TableRow {...rowData?.attributes}>
        {tableKeys.map(key => {
          const th = getValue(rowData[key])
          const thAttributes = getObject(rowData[key])?.attributes
          return (
            <TableCell
              key={`tableHead_${key}`}
              {...thAttributes}
              className={`${getCellClassNames(th, thAttributes)}`}
            >
              {getValue(rowData[key])}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

/**
 * BasicTableBody renders a set of data into a row/column display
 * @param {*} props expects three values
 * tableKeys should be an array of "key" values for each column
 * rowsData is an array of objects with properties matching the key values in tableKeys
 * tableFooter is a falsey/truthy value that indicates if a footer is present in the table this body will be inserted into
 * tableHeader is a falsey/truthy value that indicates if a header is present in the table this body will be inserted into
 * @returns an MUI TableBody component
 */
const BasicTableBody = props => {
  const { rowsData, tableKeys, tableFooter, tableHeader } = props
  return (
    <TableBody>
      {rowsData.map((row, index) => {
        return (
          <TableRow
            key={`tableRow_${index}`}
            {...row?.attributes}
            className={`${row?.attributes?.className || ''}`}
          >
            {tableKeys.map(key => {
              const td = +getValue(row[key]) === 0 ? '0' : getValue(row[key])
              const tdAttributes = getObject(row[key])?.attributes
              return (
                <TableCell
                  key={`tableData_${key}`}
                  {...tdAttributes}
                  className={`${getCellClassNames(td, tdAttributes)}`}
                  value={td}
                  sx={{
                    borderBottom: !tableFooter
                      ? index === rowsData.length - 1
                        ? 'none'
                        : ''
                      : '',
                    borderTop: !tableHeader ? (index === 0 ? 'none' : '') : '',
                  }}
                >
                  {num2numWithCommas(td)}
                </TableCell>
              )
            })}
          </TableRow>
        )
      })}
    </TableBody>
  )
}

/**
 * BasicTableFooter renders an array of data objects into cells for display
 * @param {*} props expects two values
 * tableKeys should be an array of "key" values for each column
 * rowData should be an array of key-value objects with ids matching values in the key list
 * @returns
 */
const BasicTableFooter = props => {
  const { rowData, tableKeys } = props
  return (
    <TableFooter>
      <TableRow {...rowData?.attributes}>
        {tableKeys.map(key => {
          const td = getValue(rowData[key])
          const tdAttributes = getObject(rowData[key])?.attributes
          return (
            <TableCell
              key={`tableFooter_${key}`}
              {...tdAttributes}
              sx={{ borderBottom: 'none' }}
              className={`${getCellClassNames(td, tdAttributes)}`}
            >
              {getValue(rowData[key])}
            </TableCell>
          )
        })}
      </TableRow>
    </TableFooter>
  )
}
