import React from 'react'
import './BackgroundLayers.css'
import { BASE_LAYERS_PROPS, onChangeToggleLayer, TOP_LAYERS_PROPS } from '../map.utils'

const BackgroundLayers = ({ map }) => {
  const tileLength = 3
  const rightBaseDistance = 0.5
  const styles = {
    pointerEvents: 'auto',
    height: `${tileLength}rem`,
    width: `${tileLength}rem`,
    position: 'absolute',
    padding: '10px',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'all 300ms',
    pointerEvents: 'auto',
    bottom: `${rightBaseDistance}rem`,
    zIndex: 1,
  }

  function toggleViewBaseLayerOptions() {
    document.querySelectorAll('.stack-span').forEach(span => {
      span.classList.toggle('stack-span-stacked')
    })
  }

  return (
    <>
      {BASE_LAYERS_PROPS.map(({ id, title, layers, className }, index) => (
        <button
          title={title}
          key={id}
          className={`stack-span stack-span-stacked map-img map-img-${id} ${className}`}
          onClick={() => {
            toggleViewBaseLayerOptions()
            document.getElementById('map-base-layer-expand-btn').className =
              `map-img map-img-${id} ${className}`
            Object.entries(layers).map(([layName, isVis]) => {
              onChangeToggleLayer(map, isVis, layName)
            })
          }}
          style={{
            ...styles,
            right: `${
              rightBaseDistance + tileLength + 0.5 + index * tileLength + (index + 1) * 0.5
            }rem`,
          }}
        />
      ))}

      {TOP_LAYERS_PROPS.map(({ id, title, name: layerName, className }, index) => (
        <button
          title={title}
          key={id}
          id={`map-img-${id}`}
          className={`stack-span stack-span-stacked map-img-opacity-50 map-img map-img-${id} ${
            className || ''
          }`}
          onClick={() => {
            toggleViewBaseLayerOptions()
            onChangeToggleLayer(map, null, layerName)
            document.getElementById(`map-img-${id}`).classList.toggle('map-img-opacity-50')
            const mblebc = document.getElementById('map-base-layer-expand-btn-child')
            if (mblebc) mblebc.className = `map-img map-img-${id} ${className || ''}`
          }}
          style={{
            ...styles,
            bottom: `${
              rightBaseDistance + tileLength + 0.5 + index * tileLength + (index + 1) * 0.5
            }rem`,
            right: `${rightBaseDistance}rem`,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
          }}
        />
      ))}
      <button
        id="map-base-layer-expand-btn"
        style={{ right: `${rightBaseDistance}rem`, ...styles }}
        onClick={toggleViewBaseLayerOptions}
      />
    </>
  )
}

export default BackgroundLayers
