import Input, { Button } from '../../ui/Form'
import { useSelector, useDispatch } from 'react-redux'
import React from 'react'

/* ==  MUI/MATERIAL COMPONENTS == */
import InfoIcon from '@mui/icons-material/Info'
import { makeStyles } from 'tss-react/mui'
import { Alert, AlertTitle, Box } from '@mui/material'

/* == THEME STYLES == */
import componentStyles from '../../../assets/theme/views/admin/dashboard.js'

/* ==  REDUX == */
import {
  getBaselineSelectedId,
  getConservationList,
  getBaselineList,
  getConservationSelectedIds,
  getLocation,
  getConservationPracticeResults,
  getFarmTypeId,
  getAgroforestryList,
  getLivestockResults,
} from '../../../store/selectors.js'
import {
  clearSelectedPractices,
  updateAreaInput,
} from '../../../reducers/conservationPractice.js'

/* == OTHER IMPORTS == */
import { ClearSelectionButton } from '../../ui/HoverSlide/ToolTipSpan.js'
import { useToggle } from '../../../hooks/index.js'
import { Methodology } from '../../Information/index.jsx'
import {
  AREA,
  RESULTS_HEADERS,
  RESULTS_KEYS,
  LIVESTOCK_RESULTS_KEYS,
  RESULTS_HEADER_PDF,
  TonnesCo2ePopup,
  VALID_SOIL_IDS,
  getFarmTypeNameById,
  LIVESTOCK_RESULTS_HEADERS,
  LIVESTOCK_RESULTS_HEADER_PDF,
  LAND_USE_CROP,
  LAND_USE_PASTURE_RANGELAND,
  LAND_USE_LIVESTOCK,
  TWENTY_YR_EXPLANATION,
} from '../../../constants'
import {
  calcCO2ePerYear,
  displayAgroforestryPractice,
  displayPractice,
  getLandUseIdFromBaselineId,
  calcBiomassCarbonPerYear,
  calcSoilCarbonPerYear,
  num2numWithCommas,
  numberSignClassname,
} from '../../../utils/helpers.js'
import { useState, useEffect } from 'react'
import { getX } from '../../../utils/getX.js'
import PdfReportDownload from './PdfReportDownload.jsx'
import DetailedSequestrationTable from './DetailedSequestration/index.jsx'
import { TonnesCo2ePerYearShort } from './CarbonSequestrationKey.jsx'
import BasicTable from '../../ui/BasicTable/BasicTable.jsx'
import { clearLivestockResults } from '../../../reducers/livestock.js'

/* == CHILD COMPONENTS == */
const LabelValue = props =>
  props.value && (
    <div
      style={{
        verticalAlign: 'middle',
        borderTop: '0',
        fontWeight: 400,
      }}
    >
      <span style={{ marginLeft: '0.7rem' }}>
        {props.label + '\u00A0'}|
        <b style={{ fontWeight: '600' }}>{'\u00A0' + props.value || 'N/A'}</b>
      </span>
    </div>
  )

const BaselineInfo = props => (
  <LabelValue styles={{ fontSize: '1.01em', margin: '0', padding: '0' }} {...props} />
)

const NoSoilsData = props => (
  <Alert severity="info">
    <AlertTitle>
      <h3>
        Incompatible Soil Found -
        <span style={{ color: '#351212', fontSize: '.9em', paddingLeft: '.5em' }}>
          Please select a different Location
        </span>
      </h3>
    </AlertTitle>
    <p>
      The IPCC methods used in this tool only apply to mineral soils. The soil type for your
      selected location
      {props.label && (
        <>
          , <em>{props.label}</em>,{' '}
        </>
      )}
      is not a mineral soil, so no calculations could be performed.
    </p>
    <p>If needed, use the Soils layer on the map above to see soil types in your area.</p>
  </Alert>
)

const SiteInfo = props => (
  <h2
    className="ax-flex"
    style={{
      textAlign: 'center',
      marginTop: '1em',
      marginBottom: '1em',
    }}
  >
    <LabelValue label={'Climate'} value={props.climate} />
    {!props.isLivestock && <LabelValue label={'Soil'} value={props.soil} />}
  </h2>
)

/* == DEFAULT COMPONENT == */
function ResultsCard() {
  /* == MATERIAL UI == */
  const { classes } = makeStyles()(componentStyles)()

  /* == REDUX == */
  const baselineList = useSelector(getBaselineList)
  const baselineSelectedId = useSelector(getBaselineSelectedId)

  const landUseClassId = getLandUseIdFromBaselineId(useSelector(getBaselineSelectedId))
  const conservationList = useSelector(getConservationList)
  const agroforestryList = useSelector(getAgroforestryList)
  const conservationSelectedIds = useSelector(getConservationSelectedIds)
  const conservationResults = useSelector(getConservationPracticeResults)
  const livestockResults = useSelector(getLivestockResults)
  const location = useSelector(getLocation)
  const dispatch = useDispatch()
  const farmTypeId = useSelector(getFarmTypeId)
  const isLivestock = baselineSelectedId === 8

  /* == HOOKS == */
  const [showMethodology, toggleResults_Methodology] = useToggle(false)
  const [results, setResults] = useState(conservationResults)
  const [clearedLabel, toggleClearedLabel] = useToggle()
  const [downloadingLabel, toggleDownloadingLabel] = useToggle(false)

  const conservationBaselineList = item => {
    return [
      {
        label: 'Baseline Land Use Class',
        value: item.baselineLandUseClass?.name,
      },
      {
        label: 'Baseline Input Class',
        value: item.baselineInputClass?.name,
      },
      {
        label: 'Baseline Management Class',
        value: item.baselineManagementClass?.name,
      },
      {
        label: 'Scenario Land Use Class',
        value: item.scenarioLandUseClass?.name,
      },
      {
        label: 'Scenario Input Class',
        value: item.scenarioInputClass?.name,
      },
      {
        label: 'Scenario Management Class',
        value: item.scenarioManagementClass?.name,
      },
    ]
  }
  // Update results with computed values whenever value changes
  useEffect(() => {
    const res = getX.arrayCloneSort('name', conservationResults).map(e => {
      return {
        ...e,
        co2ePerYear: calcCO2ePerYear(e),
        conservationPractice: findSelectedPractice(e),
      }
    })
    setResults(res)
  }, [conservationResults, conservationList, agroforestryList])

  // TODO need a better way of deciding which display check to use
  const showResultsRow = (item, baselineId = baselineSelectedId) => {
    return (
      (item.emissionSubcategory === 'soilCarbon'
        ? displayPractice(item, location, getLandUseIdFromBaselineId(baselineId))
        : displayAgroforestryPractice(item, location, baselineId, farmTypeId)) &&
      conservationSelectedIds.includes(item.id)
    )
  }

  const findSelectedPractice = item =>
    conservationList.find(cl => cl.id === item.id) ||
    agroforestryList.find(ap => ap.id === item.id)

  const soilDataViolationBool = () => !VALID_SOIL_IDS.includes(location?.soil?.id)

  const checkLivestockResults = () => {
    let returnVal = false
    if (livestockResults.length === 0) returnVal = true
    livestockResults.forEach(result => {
      if (result.changeTotal === null) {
        returnVal = true
      }
    })
    return returnVal
  }
  const disableDownload = () => {
    let returnVal
    if (isLivestock) {
      returnVal = checkLivestockResults()
    } else
      returnVal =
        !getX.checkArray(conservationResults) ||
        soilDataViolationBool() ||
        conservationResults.some(e => e.error !== null)
    return returnVal
  }

  const resultsSelected = getX
    .array(results)
    .filter(e => showResultsRow(findSelectedPractice(e)))

  function getBaselineName(id) {
    console.log('slabbabase', getX.array(baselineList).find(e => e.id === id)?.name)
    return getX.array(baselineList).find(e => e.id === id)?.name
  }

  const ResultsMethodologyToggleButtons = () => (
    <React.Fragment>
      <Button
        style={{ borderColor: 'transparent', border: 'none', borderRadius: '5px 5px 0 0' }}
        className={!showMethodology ? 'active' : ''}
        label={'Results'}
        onClick={() => toggleResults_Methodology(false)}
      />
      <Button
        style={{ borderColor: 'transparent', border: 'none', borderRadius: '5px 5px 0 0' }}
        className={showMethodology ? 'active' : ''}
        label={'Methodology'}
        onClick={() => toggleResults_Methodology(true)}
      />
    </React.Fragment>
  )
  const getTableRows = () => {
    if (isLivestock) {
      return livestockResults.map(e => ({
        ...e,
        animalType: e.name,
        entericCh4: e.changeCH4Enteric,
        manureCh4: e.changeCH4Manure,
        directN2o: e.changeN2ODirect,
        indirectN2oL: e.changeN2OIndirectL,
        indirectN2oV: e.changeN2OIndirectV,
        totalGhgCo2: e.changeTotal,
      }))
    }
    return resultsSelected.map(e => ({
      ...e,
      co2ePerYear: calcCO2ePerYear(e),
      conservationPractice: findSelectedPractice(e),
      co2_mean: calcCO2ePerYear(e),
      soilCarbonCo2: calcSoilCarbonPerYear(e),
      biomassCo2: calcBiomassCarbonPerYear(e),
      totalGhgCo2: calcCO2ePerYear(e),
      totalGhgCo2_min: calcCO2ePerYear(e),
      totalGhgCo2_max: calcCO2ePerYear(e),
    }))
  }
  const getTableCat = () => {
    if (isLivestock) return 'livestockPractice'
    else return 'conservationPractice'
  }

  const findPracticesToClear = () => {
    const conservationIdsToRemove = farmTypeId
      ? getX
          .arrayCloneSort('name', conservationList)
          .filter(item => !agroforestryList.some(ap => ap.name === item.name))
          .filter(item => displayPractice(item, location, landUseClassId))
      : []
    const agroforestryIdsToRemove = farmTypeId
      ? getX
          .arrayCloneSort('name', agroforestryList)
          .filter(item =>
            displayAgroforestryPractice(item, location, baselineSelectedId, farmTypeId)
          )
      : []
    // This is the list of all available soil carbon/biomass practices, send this to the reducer to clear only the applicable practices
    const idsToRemove = conservationIdsToRemove
      .concat(agroforestryIdsToRemove)
      .map(practice => practice.id)
    dispatch(clearSelectedPractices(idsToRemove))
  }

  const PdfDownloadButtons = () => (
    <div
      className=""
      style={{
        flexGrow: 1,
        marginBottom: '.25em',
        display: 'flex',
        flexDirection: 'row-reverse',
        gap: '.0em',
      }}
    >
      <ClearSelectionButton
        onClick={() => {
          toggleClearedLabel(false)
          setTimeout(toggleClearedLabel, 2000)
          isLivestock ? dispatch(clearLivestockResults()) : findPracticesToClear()
        }}
        label={clearedLabel ? 'Clear Selections' : 'Selections Cleared...'}
      />

      <PdfReportDownload
        disabled={disableDownload()}
        location={[
          ['lat', location?.lat],
          ['lon', location?.lon],
        ]}
        farmType={getFarmTypeNameById(farmTypeId)}
        climate={location?.climate?.name}
        soil={location?.soil?.name}
        label={downloadingLabel ? 'Downloading...' : 'Download Report'}
        onClick={() => {
          toggleDownloadingLabel(true)
          setTimeout(toggleDownloadingLabel, 2000)
        }}
        selectedLandUse={baselineSelectedId}
        // baseline={getBaselineName(baselineSelectedId)}
        tableData={{
          [LAND_USE_LIVESTOCK]: {
            // isLivestock,
            selectedLandUse: LAND_USE_LIVESTOCK,
            baseline: getBaselineName(LAND_USE_LIVESTOCK),
            columns: LIVESTOCK_RESULTS_HEADER_PDF,
            body: livestockResults.map(e => [
              e?.name,
              e?.scenarioPopulation - e?.baselinePopulation,
              e?.baselineProductionSystem === '49' ? 'Low' : 'High',
              e?.baselineManureStorage?.name,
              e?.scenarioProductionSystem === '49' ? 'Low' : 'High',
              e?.scenarioManureStorage?.name,
              isNaN(e?.changeCH4) ? e?.changeCH4 : Math.round(e?.changeCH4 * 100) / 100,
              isNaN(e?.changeN2O) ? e?.changeN2O : Math.round(e?.changeN2O * 100) / 100,
              // prettier-ignore
              isNaN(e?.changeTotal) ? e?.changeTotal : Math.round(e?.changeTotal * 100) / 100,
            ]),
          },
          [LAND_USE_CROP]: {
            selectedLandUse: LAND_USE_CROP,
            baseline: getBaselineName(LAND_USE_CROP),
            columns: RESULTS_HEADER_PDF,
            body: getX
              .array(results)
              .filter(r => showResultsRow(findSelectedPractice(r), LAND_USE_CROP))
              .map(e => [
                e?.name,
                e?.area,
                getX.isObject(e.co2ePerYear) ? 'Field Size Out of Bounds' : e.co2ePerYear,
              ]),
            footer: [`${TWENTY_YR_EXPLANATION}`, ''],
          },
          [LAND_USE_PASTURE_RANGELAND]: {
            selectedLandUse: LAND_USE_PASTURE_RANGELAND,
            baseline: getBaselineName(LAND_USE_PASTURE_RANGELAND),
            columns: RESULTS_HEADER_PDF,
            body: getX
              .array(results)
              .filter(r => showResultsRow(findSelectedPractice(r), LAND_USE_PASTURE_RANGELAND))
              .map(e => [
                e?.name,
                e?.area,
                getX.isObject(e.co2ePerYear) ? 'Field Size Out of Bounds' : e.co2ePerYear,
              ]),
            footer: [`${TWENTY_YR_EXPLANATION}`, ''],
          },
        }}
      />
    </div>
  )

  const ResultsDetailsRow = () => (
    <div
      className="ax-flex"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: '0.5em',
      }}
    >
      <SiteInfo
        climate={location?.climate?.name}
        soil={location?.soil?.name}
        isLivestock={isLivestock}
      />
      <DetailedSequestrationTable
        tableRows={getTableRows()}
        cat={getTableCat()}
        isLivestock={isLivestock}
      />
    </div>
  )

  const ConservationPracticeDetails = props => (
    <details
      className={conservationBaselineList(props.item).some(e => e.value) ? '' : 'no-marker'}
      key={`field-input-${props.item.id}`}
    >
      <summary>{props.conservationPractice?.name || ''}</summary>
      {conservationBaselineList(props.item).map((e, i) => (
        <div
          className={'expendable'}
          key={`${i}_baselinestuff`}
          style={{ marginLeft: '.5em', fontSize: '.9em' }}
        >
          <BaselineInfo {...e} classes={classes} />
        </div>
      ))}
    </details>
  )
  const FieldInputCell = props => (
    <React.Fragment key={`field-input-${props.item.id}`}>
      <Input
        className="field-size-input"
        type="number"
        step="any"
        style={{ textAlign: 'center', width: '8em' }}
        min={AREA.MIN}
        max={AREA.MAX}
        placeholder="Enter Field Size"
        defaultValue={props.item.area || null}
        onChange={e => {
          dispatch(
            updateAreaInput({
              id: props.item.id,
              area: parseFloat(e.target.value),
            })
          )
        }}
      />
      <div
        style={{
          fontWeight: 'bold',
          margin: 'auto',
          marginTop: '.5em',
        }}
        className={`practice-name-tco2e-value`}
      >
        <TonnesCo2ePopup
          btnProps={{
            children: (
              <>
                <span className={`${numberSignClassname(props.item.co2ePerYear)}`}>
                  {num2numWithCommas(props.item.co2ePerYear)}
                </span>{' '}
                <TonnesCo2ePerYearShort /> <InfoIcon />
              </>
            ),
          }}
          isLivestock={true}
        />
      </div>
    </React.Fragment>
  )
  const getResultKeys = () => {
    if (baselineSelectedId === 8) return LIVESTOCK_RESULTS_KEYS
    return RESULTS_KEYS
  }
  const getHeaderRow = () => {
    if (baselineSelectedId === 8) return livestockHeaderRow()
    return headerRow()
  }
  const headerRow = () => {
    const header = { ...RESULTS_HEADERS }
    for (const cell in header) {
      header[cell].attributes.classes = {
        root: classes.tableCellRoot + ' ' + classes.tableCellRootHead,
      }
    }
    return header
  }
  const livestockHeaderRow = () => {
    const header = { ...LIVESTOCK_RESULTS_HEADERS }
    for (const cell in header) {
      header[cell].attributes.classes = {
        root: classes.tableCellRoot + ' ' + classes.tableCellRootHead,
      }
    }
    return header
  }
  function GenerateResultsRows() {
    const tempRows = []

    // Make a copy of the array in order to sort it by id
    getX.arrayCloneSort('name', results).forEach(item => {
      const conservationPractice = findSelectedPractice(item)

      if (showResultsRow(conservationPractice)) {
        tempRows.push({
          practiceName: {
            value: ConservationPracticeDetails({ item, conservationPractice }),
            attributes: {
              className: 'results-table-practice-cell',
              classes: {
                root: classes.tableCellRoot + ' ' + classes.tableCellRootBodyHead,
              },
              style: { maxWidth: '200px' },
              component: 'th',
              variant: 'head',
              scope: 'row',
            },
          },
          fieldSize: {
            value: FieldInputCell({ item }),
            attributes: {
              className: 'field-size-input-cell',
              classes: { root: classes.tableCellRoot },
            },
          },
          result: {
            value: num2numWithCommas(item.co2ePerYear),
            attributes: {
              className: `results-table-tco2e-cell ${numberSignClassname(item.co2ePerYear)}`,
              classes: { root: classes.tableCellRoot },
              style: {
                color: isNaN(item.co2ePerYear) ? 'var(--cancel-color)' : '',
              },
            },
          },
        })
      }
    })

    return tempRows
  }
  function GenerateLivestockResultsRows() {
    const tempRows = []

    // Make a copy of the array in order to sort it by name
    getX.arrayCloneSort('name', livestockResults).forEach(lr => {
      if (lr.changeTotal != null)
        tempRows.push({
          speciesName: {
            value: lr.name,
            attributes: {
              className: 'results-table-practice-cell',
              classes: {
                root: classes.tableCellRoot + ' ' + classes.tableCellRootBodyHead,
              },
              style: { maxWidth: '200px' },
              component: 'th',
              variant: 'head',
              scope: 'row',
            },
          },
          ch4Result: {
            value: lr.changeCH4,
            attributes: {
              className: `results-table-tco2e-cell ${numberSignClassname(lr.changeCH4)}`,
              classes: { root: classes.tableCellRoot },
              style: {
                color: isNaN(lr.changeCH4) ? 'var(--cancel-color)' : '',
              },
            },
          },
          n2oResult: {
            value: lr.changeN2O,
            attributes: {
              className: `results-table-tco2e-cell ${numberSignClassname(lr.changeN2O)}`,
              classes: { root: classes.tableCellRoot },
              style: {
                color: isNaN(lr.changeN2O) ? 'var(--cancel-color)' : '',
              },
            },
          },
          result: {
            value: lr.changeTotal,
            attributes: {
              className: `results-table-tco2e-cell ${numberSignClassname(lr.changeTotal)}`,
              classes: { root: classes.tableCellRoot },
              style: {
                color: isNaN(lr.changeTotal) ? 'var(--cancel-color)' : '',
              },
            },
          },
        })
    })
    return tempRows
  }
  const [reportRows, setRows] = useState(() => {
    if (results && results.length > 0) {
      GenerateResultsRows()
    }
  })
  useEffect(() => {
    if (!isLivestock && results) {
      setRows(GenerateResultsRows())
    }
  }, [results, conservationSelectedIds])

  useEffect(() => {
    if (livestockResults) {
      setRows(GenerateLivestockResultsRows())
    }
  }, [livestockResults])

  useEffect(() => {
    if (isLivestock && livestockResults.length > 0) {
      setRows(GenerateLivestockResultsRows())
    } else {
      setRows([])
    }
  }, [landUseClassId])
  return (
    <Box style={{ maxWidth: '100%', margin: ' .75em' }}>
      <div className="resultscard-btns clear-me-floats" style={{ display: 'flex' }}>
        <ResultsMethodologyToggleButtons />
        <PdfDownloadButtons />
      </div>
      {showMethodology && <Methodology />}
      {!showMethodology && (
        <div style={{ overflow: 'auto' }}>
          {farmTypeId &&
            landUseClassId &&
            location?.lat &&
            location?.lon &&
            (!isLivestock && soilDataViolationBool() ? (
              <NoSoilsData label={location?.soil?.name} />
            ) : (
              <div>
                <ResultsDetailsRow />
                <BasicTable
                  className="resultscard-table"
                  tablestyle={{
                    width: 'unset',
                    margin: '1em auto',
                    overflow: 'auto',
                  }}
                  tableKeys={getResultKeys()}
                  tableHeader={getHeaderRow()}
                  tableRows={reportRows}
                />
              </div>
            ))}
        </div>
      )}
    </Box>
  )
}

export default ResultsCard
