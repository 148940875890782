import React, { useState } from 'react'
import { transform } from 'ol/proj.js'
import { IconButton } from './map.ui.tsx'

export default function LocationSearchbar({ map, ...rest }) {
  const [searchText, setSearchText] = useState('')

  const handleTextChange = event => {
    setSearchText(event.target.value)
  }

  const checkKeyPress = e => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      e.preventDefault()
      searchLocation()
    }
  }

  const searchLocation = event => {
    fetch(
      `https://dev.virtualearth.net/REST/v1/Locations?query=${encodeURIComponent(
        searchText
      )}&key=${process.env.REACT_APP_BING_MAP_KEY}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(response => {
        return response.blob()
      })
      .then(blob => {
        var reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = function () {
          try {
            var base64data = reader.result
            //chrome and firefox put different things at the start of the string, but "base64," seems to be consistent
            const json = atob(base64data.split('base64,')[1])
            const result = JSON.parse(json)
            var lat = result.resourceSets[0].resources[0].geocodePoints[0].coordinates[0]
            var long = result.resourceSets[0].resources[0].geocodePoints[0].coordinates[1]
            var boundingBox = result.resourceSets[0].resources[0].bbox
            map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'))
            map.getView().setZoom(getZoomLevel(boundingBox))
          } catch (error) {
            console.warn('location search error: ', error)
            //todo: pop up "location not found" warning message so the user has some feedback
          }
        }
      })
  }

  const getZoomLevel = boundingBox => {
    const yDifference = boundingBox[0] - boundingBox[2]
    const xDifference = boundingBox[1] - boundingBox[3]
    const distance = Math.sqrt(xDifference * xDifference + yDifference * yDifference)

    //4PL curve provided by mycurvefit.com from sample points
    return -144.3429 + 180.11539 / (1 + Math.pow(distance / 87703640000, 0.07238359))
  }

  return (
    <div id="locationSearchPanel" {...rest}>
      <div className="map-searchbox">
        <input
          type="text"
          placeholder="Search for a location"
          defaultValue=""
          id="locationSearchField"
          onKeyDown={checkKeyPress}
          onChange={handleTextChange}
        />
        <IconButton onClick={searchLocation} title="Search" style={{ border: 'none' }}>
          <MagnifyingGlass />
        </IconButton>
      </div>
    </div>
  )
}

const MagnifyingGlass = ({ height, width }) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height={height || '1em'}
      width={width || '1em'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
    </svg>
  )
}
