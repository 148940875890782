import InfoIcon from '@mui/icons-material/Info'

import cropland_management from '../assets/crop-icons/cropland_management.png'
import grazing_lands from '../assets/crop-icons/grazing_lands.png'
import wood_cover from '../assets/crop-icons/wood_cover.png'
import livestock_placeholder from '../assets/crop-icons/livestock.png'
import Dialog from '../components/ui/Dialog'
import {
  TonnesCo2ePerYearLong,
  TonnesCo2ePerYearMedium,
  TonnesCo2ePerYearString,
} from '../components/Cards/ResultsCard/CarbonSequestrationKey'

export const LARGE_FARM = 'Commercial / Plantation'
export const SMALL_FARM = 'Smallholder'

export const getFarmTypeNameById = id => {
  if (id === 1500) return SMALL_FARM
  if (id === 1501) return LARGE_FARM
  return null
}

export const BASELINE_LANDUSE_TXT = 'Current Land Use'
export const CULTIVATED_ANN_CROPLAND = 'Cultivated Annual Croplands'
export const FARM_LOCATION = 'Farm Location'
export const FARM_TYPE = 'Farm Type'
export const LIVESTOCK_SPECIES = 'Livestock Species'
export const PASTURE_RANGELANDS = 'Pastures and Rangelands'
export const PERENNIAL_TREE_CROPS = 'Perennial Tree Crops'

// Indicates which land uses are included under a given label
export const LAND_USE_ALL = [0, 1, 2, 8]
export const LAND_USE_CONSERVATION = [0, 1, 2]
export const LAND_USE_CROP = 1
export const LAND_USE_PASTURE_RANGELAND = 2
export const LAND_USE_LIVESTOCK = 8

export const ACRE = 'acre'
export const HECTARE = 'hectare'

export const AREA = {
  MIN: 0,
  MAX: 1000,
}

export const LANDUSE_IMAGES = {
  1: cropland_management,
  2: grazing_lands,
  6: wood_cover,
  7: wood_cover,
  8: livestock_placeholder,
}

export const STEP_TITLES = {
  landLocation: {
    title: `Find ${FARM_LOCATION}`,
    subTitle:
      'Zoom to your location and click the point on the map that you would like to evaluate',
    landUse: LAND_USE_ALL,
  },
  farmType: { title: `Choose ${FARM_TYPE}`, landUse: LAND_USE_ALL },
  conservationPractice: {
    title: 'Select Conservation Practice',
    landUse: LAND_USE_CONSERVATION,
  },
  ghgReduction: {
    title: 'Carbon Sequestration Estimation',
    subTitle:
      'Enter the area (in hectares) associated with each conservation practice you selected',
    landUse: LAND_USE_CONSERVATION,
  },
  baselineLandUse: { title: `Choose ${BASELINE_LANDUSE_TXT}`, landUse: LAND_USE_ALL },
  animalType: {
    title: `Select ${LIVESTOCK_SPECIES}`,
    subTitle:
      'Please use the checkboxes below to select any livestock species to include in the assessment.',
    landUse: [LAND_USE_LIVESTOCK],
  },
  animalBaselineManagement: {
    title: 'Livestock Baseline Management',
    subTitle:
      'Indicate the baseline, or current, management system for the selected livestock species',
    landUse: [LAND_USE_LIVESTOCK],
  },
  animalConservationManagement: {
    title: 'Livestock Scenario Management',
    subTitle: 'Indicate the change in management for the selected livestock species',
    landUse: [LAND_USE_LIVESTOCK],
  },
  livestockReduction: {
    title: 'Greenhouse Gas Emission Reductions',
    // subTitle: "Enter the area to which the conservation practice would be applied",
    landUse: [LAND_USE_LIVESTOCK],
  },
}

export const FieldSizePopup = ({ btnProps }) => (
  <Dialog
    btnProps={{
      ...{
        style: { fontWeight: 'normal', color: 'inherit' },
        children: (
          <span style={{ textWrap: 'none' }}>
            Field Size{' '}
            <span style={{ whiteSpace: 'pre' }}>
              (hectares) <InfoIcon />
            </span>
          </span>
        ),
      },
      ...btnProps,
    }}
    header={<span style={{ textTransform: 'none' }}>Field Size (hectares)</span>}
    style={{ maxWidth: '800px', margin: 'auto' }}
  >
    <div style={{ padding: '0 1em' }}>{STEP_TITLES.ghgReduction.subTitle}</div>
  </Dialog>
)

export const TonnesCo2ePopup = ({ btnProps }) => (
  <Dialog
    btnProps={{
      ...{
        style: { fontWeight: 'normal', color: 'inherit' },
        children: (
          <span style={{ textWrap: 'none' }}>
            <TonnesCo2ePerYearMedium />
            {' * '}
            <InfoIcon />
          </span>
        ),
      },
      ...btnProps,
    }}
    header={
      <span style={{ textTransform: 'none' }}>
        Carbon Sequestration (<TonnesCo2ePerYearLong />)
      </span>
    }
    style={{ maxWidth: '800px', margin: 'auto' }}
  >
    Approximate carbon sequestration and greenhouse gas emission reduction estimates are given
    in{' '}
    <b>
      tonnes CO<sub>2</sub> equivalent per year
    </b>
    , where:
    <div style={{ padding: '0 1em' }}>
      <p>
        <b>tonnes = Metric Tonnes</b> (same as Megagrams) <br />
        Metric Tonnes are similar to English (or ‘short’) tons; 1 Metric Ton = 1.1 English
        (short) tons
      </p>

      <p>
        <b>
          CO<sub>2</sub> equivalent = Carbon Dioxide Equivalent
        </b>
        <br />
        Carbon dioxide equivalent is a measure used to compare the emissions from various
        greenhouse gases, based upon their global warming potential. Carbon dioxide equivalents
        <br /> are used in COMET-Planner to allow users to compare emission reductions of
        carbon dioxide, nitrous oxide and methane in standardized units.
      </p>
    </div>
  </Dialog>
)
export const MiniHelp = ({ btnProps, header, dialogHeader, text }) => (
  <Dialog
    btnProps={{
      ...{
        children: (
          <span style={{ textWrap: 'none' }}>
            {header} <InfoIcon />
          </span>
        ),
      },
      ...btnProps,
    }}
    header={<span style={{ textTransform: 'none' }}>{header}</span>}
    style={{ maxWidth: '800px', margin: 'auto' }}
  >
    {text}
  </Dialog>
)

export const RESULTS_KEYS = ['practiceName', 'fieldSize', 'result']
export const LIVESTOCK_RESULTS_KEYS = ['speciesName', 'ch4Result', 'n2oResult', 'result']

export const RESULTS_HEADERS = {
  practiceName: {
    value: 'Conservation Practice',
    attributes: {
      className: 'results-table-practice-cell',
      style: { textTransform: 'none', whiteSpace: 'normal' },
    },
  },
  fieldSize: {
    value: <FieldSizePopup />,
    attributes: {
      className: 'field-size-input-cell',
      style: { textTransform: 'none', whiteSpace: 'normal' },
    },
  },
  result: {
    value: (
      <>
        <span className="desktop-only">Carbon Sequestration </span>
        <TonnesCo2ePopup />
      </>
    ),
    attributes: {
      className: 'results-table-tco2e-cell',
      style: { textTransform: 'none', whiteSpace: 'normal' },
    },
  },
}

export const LIVESTOCK_RESULTS_HEADERS = {
  speciesName: {
    value: 'Livestock Species',
    attributes: {
      className: 'results-table-practice-cell',
      style: { textTransform: 'none', whiteSpace: 'normal' },
    },
  },
  ch4Result: {
    value: (
      <>
        <span className="desktop-only">Methane</span>
        <TonnesCo2ePopup />
      </>
    ),
    attributes: {
      className: 'results-table-tco2e-cell',
      style: { textTransform: 'none', whiteSpace: 'normal' },
    },
  },
  n2oResult: {
    value: (
      <>
        <span className="desktop-only">Nitrous Oxide</span>
        <TonnesCo2ePopup />
      </>
    ),
    attributes: {
      className: 'results-table-tco2e-cell',
      style: { textTransform: 'none', whiteSpace: 'normal' },
    },
  },
  result: {
    value: (
      <>
        <span className="desktop-only">Reductions Total</span>
        <TonnesCo2ePopup />
      </>
    ),
    attributes: {
      className: 'results-table-tco2e-cell',
      style: { textTransform: 'none', whiteSpace: 'normal' },
    },
  },
}

export const CONSERVATION_PRACTICE_HEADERS = [
  /**
   * NOTE: not in use as of 04/2023
   * saving if needed later
   */
  'Baseline Land Use Class',
  'Baseline Input Class',
  'Baseline Management Class',
  'Scenario Land Use Class',
  'Scenario Input Class',
  'Scenario Management Class',
]

export const LIVESTOCK_RESULTS_HEADER_PDF = [
  'Livestock Species',
  'Population Change',
  'Baseline Production System',
  'Baseline Manure Storage',
  'Scenario Production System',
  'Scenario Manure Storage',
  `Methane Reductions (${TonnesCo2ePerYearString})*`,
  `Nitrogen Oxide Reductions (${TonnesCo2ePerYearString})*`,
  `Reductions Total (${TonnesCo2ePerYearString})*`,
]

export const RESULTS_HEADER_PDF = Object.keys(RESULTS_HEADERS).map(
  // export const RESULTS_HEADER_PDF = [...RESULTS_HEADERS, ...CONSERVATION_PRACTICE_HEADERS].map(
  (e, i) => {
    if (i === 0) return 'Conservation Practice'
    if (i === 1) return 'Field Size (ha)'
    if (i === 2) return `Carbon Sequestration (${TonnesCo2ePerYearString})*`
    if (i === 3) return 'Carbon Sequestration (Tonnes CO2e over 20 years)'
    return e
  }
)

export const TWENTY_YR_EXPLANATION = `*Results reflect annualized carbon stock changes after 20 years of conservation practice adoption, compared to a baseline carbon stock.
  A positive value indicates carbon sequestration due to adoption of a conservation practice.`

export const NA_SOILS_DATA_INFO = `**The IPCC methods used in this tool apply to mineral soils only. The ${FARM_LOCATION} selected is not reported as a mineral soil.`

export const DATA_BASE_NO_SOILS_DATA = 'No soils data'

/**
 * AS of 2023/04 valid and invalid soil type ids are sequential and perfectly balanced 6 and 6
 */
export const VALID_SOIL_IDS = Array.from({ length: 6 }, (_, i) => i + 10101)
export const INVALID_SOIL_IDS = Array.from({ length: 6 }, (_, i) => i + 10107)
