import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { getX } from '../../utils/getX'
import { Box } from '@mui/material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export default function CustomizedDialogs({
  btnProps,
  children,
  header,
  footer,
  disableFooter,
  isOpen,
  onClose,
  ...rest
}) {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    getX.isFunct(onClose) && onClose()
    setOpen(false)
  }
  return (
    <>
      {btnProps && (
        <Box
          {...btnProps}
          style={{ cursor: 'pointer', ...btnProps.style }}
          onClick={() => setOpen(true)}
        />
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={typeof isOpen === 'boolean' ? isOpen : open}
        fullWidth
        {...rest}
      >
        {header && (
          <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="dialog-title">
              {header}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        )}

        <DialogContent style={{ position: 'relative' }} dividers>
          {children}
        </DialogContent>

        {!disableFooter && (
          <DialogActions style={{ gap: '1em', display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>{footer}</div>
            <button autoFocus onClick={handleClose}>
              Close
            </button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </>
  )
}
