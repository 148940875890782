import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  List,
  Typography,
  Alert,
  AlertTitle,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'
import {
  GiCow as Cattle,
  GiChicken as Poultry,
  GiPig as Swine,
  GiDeer as Other,
} from 'react-icons/gi'

import componentStyles from '../../../assets/theme/views/admin/dashboard.js'
import {
  getAnimalTypesList,
  getAnimalTypesSelectedIds,
  getBaselineSelectedId,
  getFarmTypeId,
  getLocation,
} from '../../../store/selectors.js'
import { getLandUseIdFromBaselineId } from '../../../utils/helpers'
import { BASELINE_LANDUSE_TXT, FARM_LOCATION, FARM_TYPE } from '../../../constants'
import { deselectAnimalType, selectAnimalType } from '../../../reducers/livestock.js'
import './AnimalStyle.css'
import PillFilters from '../../ui/PillFilters'

const AnimalType = () => {
  const { classes } = makeStyles()(componentStyles)()
  const location = useSelector(getLocation)
  const farmTypeId = useSelector(getFarmTypeId)
  const baselineLandUseId = useSelector(getBaselineSelectedId)
  const animalTypesList = useSelector(getAnimalTypesList)
  const animalTypesSelectedIds = useSelector(getAnimalTypesSelectedIds)

  //wasn't going to hardcode this but since we are adding pictures it made sense
  const animalSubGroups = [
    {
      name: 'Cattle',
      icon: <Cattle aria-label="Cattle" />,
    },
    { name: 'Poultry', icon: <Poultry aria-label="Poultry" /> },
    { name: 'Swine', icon: <Swine aria-label="Swine" /> },
    { name: 'Other', icon: <Other aria-label="Other" /> },
  ]

  const [subGroups, setSubGroups] = useState([])
  const dispatch = useDispatch()

  // This strategy may need to change as we add more baseline classes. Currently the
  // baselineLandUseClasses in the ConservationPractice list divide nicely along Baseline Practices
  // (e.g. baselineLandUseClass 9708 always lines up with Cropland and 9702 always lines up with
  // Pasture/Rangeland), but as we add in more baseline classes that may not always be the case.
  // We may end up needing to add a BaselinePractice field to the ConservationPractice object
  // that contains the lookup id of the Baseline Practice it's associated with, and then pass in
  // baselineSelectedId to the displayPractice function and filter by that instead of landUseClassId.
  const landUseClassId = getLandUseIdFromBaselineId(baselineLandUseId)

  const handleCategorySectionChange = (event, newCategories) => {
    setSubGroups(newCategories)
  }

  const isHidden = animal => {
    return !(subGroups.length === 0 || subGroups.includes(animal.subGroup))
  }

  const updateAnimalTypes = animalId => () => {
    const animalIndex = animalTypesSelectedIds.indexOf(animalId)

    if (animalIndex !== -1) {
      // ID is already in list, remove
      dispatch(deselectAnimalType(animalId))
    } else {
      // Add ID to list
      dispatch(selectAnimalType(animalId))
    }
  }

  const AnimalTypeSelectionItem = animal => (
    <ListItem
      onClick={updateAnimalTypes(animal.id)}
      dense
      button
      role={undefined}
      classes={{ root: classes.muiListItemRoot }}
    >
      <ListItemIcon classes={{ root: classes.muiListItemIconRoot666 }}>
        <Checkbox edge="start" checked={animalTypesSelectedIds.indexOf(animal.id) !== -1} />
      </ListItemIcon>
      <ListItemText primary={animal?.name} />
    </ListItem>
  )
  const AwaitUserSelectionsMessage = () => (
    <Alert severity="warning" className="prose">
      <AlertTitle>
        <h3>Selections Missing</h3>
      </AlertTitle>
      {!(location?.lat && location?.lon) && <li>Please Select Farm Location on the map</li>}
      {!farmTypeId && <li>Please Select a Farm Type</li>}
      {!landUseClassId && <li>Please Select a current Land Use</li>}
    </Alert>
  )
  const NoAnimalTypesAvailableMessage = () => (
    <Box className="no-practice-warning">
      <Alert severity="info">
        <AlertTitle>
          <Typography variant="h3">No Livestock Species available</Typography>
        </AlertTitle>
        <br />
        Please try a different
        <HighlightedText txt={FARM_TYPE + ','} />
        <HighlightedText txt={FARM_LOCATION + ','} /> and
        <HighlightedText txt={BASELINE_LANDUSE_TXT} />
        combination
      </Alert>
    </Box>
  )

  const filterSubGroups = animalSubGroups.map(a => ({
    id: a.name,
    label: a.name,
    icon: a.icon,
  }))
  return (
    <List
      id="animaltype"
      style={{
        width: '100%',
        padding: '.5em',
        overflow: 'auto',
        position: 'relative',
        height: '100%',
      }}
    >
      {farmTypeId && landUseClassId && location?.lat && location?.lon && (
        <Box className="animalType-list">
          {animalTypesList.length > 0 && location?.climate ? (
            <Box>
              <PillFilters pills={filterSubGroups} onClick={setSubGroups} />
              {/* <TabFilter {...{ subGroups, handleCategorySectionChange, animalSubGroups }} /> */}
              {/* <hr /> */}
              <Grid fullwidth="true" container m={1} sx={{ justifyContent: 'flex-start' }}>
                {animalTypesList.map(animal => (
                  <Grid
                    key={`grid ${animal.id}`}
                    hidden={isHidden(animal)}
                    item
                    sx={{ width: '18em' }}
                    // xs={12}
                    // s={12}
                    // md={6}
                    // l={4}
                    // xl={3}
                  >
                    <AnimalTypeSelectionItem key={animal.id} {...animal} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <NoAnimalTypesAvailableMessage />
          )}
        </Box>
      )}
      {/* If the user hasn't selected a farm type, current land use, or location, display corresponding error messages */}
      {!(farmTypeId && landUseClassId && location?.lat && location?.lon) && (
        <AwaitUserSelectionsMessage />
      )}
    </List>
  )
}

export default AnimalType

const HighlightedText = ({ txt }) => <b style={{ fontSize: '1.051em' }}> {txt} </b>

const TabFilter = ({ subGroups, handleCategorySectionChange, animalSubGroups }) => (
  <Grid key={`animalSubgroups`} item xs={12}>
    <ToggleButtonGroup
      //fullWidth causes a warning but works?
      fullWidth={true}
      key={`animalSubgroupToggles`}
      value={subGroups}
      onChange={handleCategorySectionChange}
      aria-label="animal-category-selecting"
      sx={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}
    >
      {animalSubGroups?.map(subgroup => (
        <ToggleButton
          key={`animalSubgroup ${subgroup.name}`}
          value={subgroup.name}
          aria-label={subgroup.name}
          sx={{
            width: '10em',
            borderRadius: '1em !important',
            margin: '.5em !important',
            border: '1px solid rgba(0, 0, 0, 0.12) !important',
          }}
        >
          {subgroup.icon}
          {subgroup.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  </Grid>
)
