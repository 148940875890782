//@ts-nocheck
import React, { ReactNode } from 'react'
import { Map as OlMap } from 'ol'
import 'ol/ol.css'
import { SliderBar } from '../map.components/map.ui.tsx'
import {
  onChangeToggleLayer,
  adjustMapOpacity,
  TOGGLEABLE_LAYERS_PROPS,
  getLayerSupplementaryElementClassName,
  getLayerMapKeyElementClassName,
} from '../map.utils'
import '../map.css'
import { sortX } from '../doX'

import { MapLayerBasics } from '../map.typedefs'
import { Checkbox } from '@mui/material'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import { ContinentalUSMessage } from '../../Information/index.jsx'
import { Button } from '../../ui/Form.jsx'
import { LAYER_NAMES } from '../map.constants.jsx'

import { MapIcon } from '../map.assets/svgs'
import CloseIcon from '@mui/icons-material/Close'

type OlMapType = { map: OlMap | null } & React.HTMLAttributes<HTMLDivElement>

export const ScienceLayers = ({ map, className }: OlMapType) => {
  return (
    <div className={className}>
      <details
        open
        className="map-interaction-layers-wrapper map-interaction-layers-wrapper-secondary details-open-close-arrow"
      >
        <summary>
          <span style={{ fontSize: '1rem', fontWeight: '400', marginRight: '.5em' }}>
            Map Layers
          </span>
          <ExpandCircleDownIcon
            style={{ height: '1em', width: '1em' }}
            className="map-layer-summary-icon"
          />
        </summary>

        {TOGGLEABLE_LAYERS_PROPS.map(({ label, opacity, notes }) => {
          const { plural: layerName } = label
          const layerClassName = getLayerSupplementaryElementClassName(layerName)
          return (
            <div key={layerName}>
              <label
                className="flex items-center cursor-pointer"
                style={{ marginRight: '.75em' }}
              >
                <Checkbox
                  onChange={e => {
                    onChangeToggleLayer(map, e.target.checked, layerName)
                    const elements = document.getElementsByClassName(layerClassName)
                    for (let i = 0; i < elements.length; i++) {
                      e.target.checked
                        ? elements[i]?.classList.remove('hidden')
                        : elements[i]?.classList.add('hidden')
                    }
                  }}
                />
                <span className="map-layer-ui-label">
                  {layerName}
                  <span className={`hidden ${layerClassName}`}>{notes && '*'}</span>
                </span>
              </label>
              <div className={`hidden ${layerClassName}`}>
                <SliderBar
                  defaultValue={opacity * 100}
                  onChange={e => {
                    const elements = document.getElementsByClassName(
                      getLayerMapKeyElementClassName(layerName)
                    )
                    for (let i = 0; i < elements.length; i++) {
                      // @ts-expect-error
                      elements[i].style.opacity = +e.target.value / 100
                    }
                    // @ts-ignore
                    adjustMapOpacity(map, e?.target?.value, layerName)
                  }}
                />
                <div>{notes}</div>
              </div>
            </div>
          )
        })}
      </details>
    </div>
  )
}

const LayerKey = ({ layerKeys }) => (
  <>
    {sortX.sortByKeyAscString(layerKeys?.fillElements, 'name')?.map((ck: any, i: number) => {
      return (
        <div key={ck?.name || i} className="flex items-center gap-2">
          <div className="map-key-wrapper" style={{ borderColor: ck?.fill }}>
            <div
              className={`map-key-item ${getLayerMapKeyElementClassName(layerKeys?.name)}`}
              style={{ color: ck?.fill, backgroundColor: ck?.fill }}
            />
          </div>
          - <span>{ck?.name}</span>
        </div>
      )
    })}
  </>
)
export const MapKeys = ({ colorKey }: { colorKey: any }) => (
  <div
    style={{ padding: 0 }}
    className="map-interaction-card map-interaction-card map-layer-key-container pointer-events-auto"
  >
    {colorKey.map((layerKeys: any, k: number) => {
      return (
        <div
          key={k}
          className={`${getLayerSupplementaryElementClassName(
            layerKeys?.name
          )} hidden relative`}
          style={{ padding: '.5em' }}
        >
          <h3>{layerKeys?.name}</h3>
          <LayerKey layerKeys={layerKeys} />
        </div>
      )
    })}
  </div>
)

export const MapInfoPopup = ({ state }: { state: MapLayerBasics[] }) => {
  const isContainsAnyNoData = (() => {
    const h = state.reduce((acc, s) => ({ ...acc, [s?.group]: s?.isNoData }), {})

    const climNoData = h?.[LAYER_NAMES.CLIMATE.group]
    const soilNoData = h?.[LAYER_NAMES.SOIL_GLOBAL.group]
    const ssoilNoData = h?.[LAYER_NAMES.SOIL_NATIONAL.group]
    const terryNoData = h?.[LAYER_NAMES.TERRITORY.group]

    return climNoData || (soilNoData && ssoilNoData) || terryNoData
  })() // would be better if we could use something like this.... state.some(s => s.isNoData)

  // const warningColorHex = "#63a0ad" // "#bb861c" //"#5393a0" // "#df3336"
  const warningStyles = { color: '#63a0ad', fontStyle: 'italic' }
  return (
    <div
      className={`map-information-popup`}
      style={{ opacity: state.some(e => e.value) ? 1 : 0 }}
    >
      {state
        .filter(e => ![LAYER_NAMES.USALAYER.group].includes(e?.group))
        .map(({ label, value, isNoData }) => {
          return (
            <div key={label.singular}>
              {label.singular}:
              <span className="map-transition" style={isNoData ? warningStyles : {}}>
                {' '}
                {value || 'No Data'}
                {isNoData ? '*' : ''}
              </span>
            </div>
          )
        })}

      <div
        className="map-transition"
        style={{
          display: 'none',
          maxWidth: '400px',
          fontSize: '.85em',
          maxHeight: '3em',

          height: isContainsAnyNoData ? '600px' : 0,
          opacity: isContainsAnyNoData ? 1 : 0,
          marginTop: '.25em',
          ...warningStyles,
        }}
      >
        <ul
          className="map-transition"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: isContainsAnyNoData ? '400px' : 0,
            maxWidth: 'fit-content',
            transition: 'all 750ms',
          }}
        >
          <li
            style={{
              marginLeft: '.00005em',
              fontSize: '1.1em',
              fontWeight: 'bold',
            }}
          ></li>
          <li>
            *Some <b>Land Uses</b> may not be available at this farm location.
          </li>
          {/* <li>*Calculations cannot be performed in areas with No Data.</li> */}
        </ul>
      </div>
    </div>
  )
}

export const ContinentalUSPopup = props => (
  <>
    <div className="information-container" style={{ margin: 'auto', fontSize: '1.1em' }}>
      <ContinentalUSMessage />
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 10,
        gap: '.7em',
      }}
    >
      <Button children="Stay here" onClick={() => props.close()} />
      <Button
        className={'active'}
        children={'Go to COMET-Planner'}
        onClick={() => {
          window.open('http://comet-planner.com', '_blank')
        }}
      />
    </div>
  </>
)

export const MapCloseExample = ({ children }: { children: ReactNode }) => (
  <details open className="map-interaction-layers-wrapper">
    <summary
      className="map-interaction-card pointer-events-auto flex gap-2 items-center"
      title="Map Layers"
    >
      <span>
        <span
          className="map-layers-closed-txt "
          style={{ marginRight: '.5em', display: 'flex' }}
        >
          <span className="desktop-only">Map Layers</span>
          <MapIcon className="mobile-only" height="1.75rem" width="1.75rem" />
        </span>
        <span className="map-layers-open-txt ">Close</span>
      </span>
      <CloseIcon className="map-layer-x-icon" style={{ height: '1em', width: '1em' }} />
    </summary>
    {children}
  </details>
)
