import { useState, useEffect } from 'react'
import PlannerGlobalPage from '../../components/PageLayout/PlannerGlobalPage'
import Welcome from '../../components/PageLayout/Welcome'
import Dashboard from './Dashboard'
import { ContinentalUSMessage } from '../../components/Information'
import { Link, Box, Button } from '@mui/material'
import Dialog from '../../components/ui/Dialog'
import UseAgreement from '../../components/Documentation/UseAgreement'
import { SOILSSURGO_LAYER_NAME } from '../../constants/mapLayers'

const HomePage = () => {
  const homePageWelcome = {
    header: 'Welcome to COMET-Planner Global',
    body: (
      <Box>
        <p>
          COMET-Planner Global provides estimates of carbon sequestration and greenhouse gas
          emission reductions for common conservation agriculture practices across the world.
          Estimates were generated for broad climate and soil categories using{' '}
          <Link href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/vol4.html">
            UNFCCC Intergovernmental Panel on Climate Change Guidelines for National Greenhouse
            Gas Inventories.
          </Link>
        </p>
        <ContinentalUSMessage />
      </Box>
    ),
    footer: (
      <Link
        component={Button}
        className="link-btn"
        href={process.env.PUBLIC_URL + 'COMET-Planner-Global-How-To.pdf'}
      >
        User Guide
      </Link>
    ),
  }

  /* --- Display Use Agreement when the home page first loads --- */
  // const [showUserAgreement, setShowUserAgreement] = useState(false);

  // const setUserAgreementSeen = () => {
  //   setShowUserAgreement(false);
  //   localStorage.setItem("CometPlannerGlobalUseAgreementSeen", "True");
  // };

  // useEffect(() => {
  //   if (localStorage.getItem("CometPlannerGlobalUseAgreementSeen") !== "True") {
  //     setShowUserAgreement(true);
  //   }
  // }, []);

  return (
    <PlannerGlobalPage>
      <Welcome {...homePageWelcome} />
      <Dashboard />

      {/* <Dialog
        isOpen={showUserAgreement}
        onClose={setUserAgreementSeen}
        header="COMET-Planner Global Use Agreement"
      >
        <UseAgreement />
      </Dialog> */}
    </PlannerGlobalPage>
  )
}
export default HomePage
