import PlannerGlobalPage from '../PageLayout/PlannerGlobalPage'
import Welcome from '../PageLayout/Welcome'
import { Box, Link, Typography, Grid } from '@mui/material'
import Dialog from '../ui/Dialog'
import Changelog from './Changelog/Changelog'

const Documentation = () => {
  const documentationWelcome = {
    header: 'COMET-Planner Global Documentation',
    body: (
      <p>
        Included below are links to assorted documentation associated with COMET Planner
        Global, as well as the other tools in the COMET suite. NOTE: This page is still in
        development, and can be expected to change.
      </p>
    ),
  }

  return (
    <PlannerGlobalPage>
      <Welcome {...documentationWelcome} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        px="3em"
        py="2em"
        marginLeft="2em"
        height="70.5vh"
      >
        <Grid container direction="column" width="1200px" maxWidth="100vw">
          <Typography variant="h2" fontSize="1.3em" fontWeight="bold">
            Resources
          </Typography>

          <Dialog
            header="COMET-Planner Global Changelog"
            btnProps={{
              children: 'Changelog',
              component: Link,
              style: { cursor: 'pointer' },
            }}
            sx={{
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  maxWidth: '900px',
                },
              },
            }}
          >
            <Changelog />
          </Dialog>

          {/* <Dialog
            header="Use Agreement"
            btnProps={{
              children: "Use Agreement",
              component: Link,
              style: { cursor: "pointer" },
            }}
          >
            Use Agreement Placeholder
          </Dialog> */}

          <Typography variant="h2" fontSize="1.3em" fontWeight="bold" pt="1.5em">
            COMET Tools Suite
          </Typography>
          <Link href="http://comet-planner.com/" rel="noopener roreferrer" target="_blank">
            COMET Planner
          </Link>

          <Link href="https://comet-farm.com/" rel="noopener roreferrer" target="_blank">
            COMET Farm
          </Link>
        </Grid>
      </Box>
    </PlannerGlobalPage>
  )
}
export default Documentation
