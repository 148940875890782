export const CLIMATE_LAYER_NAME = 'Climate Regions'
export const SOILS_LAYER_NAME = 'Global Level Soils'
export const SOILSSURGO_LAYER_NAME = 'National Level Soils (Beta)'
export const COUNTRIES_LAYER_NAME = 'Countries'
export const HIGHLIGHTED_COUNTRY_LAYER_NAME = 'Highlighted Country'
export const USA_LAYER_NAME = 'Continental USA'
export const BASE_LAYER_NAME = 'Base'
export const MAPLAYER_DEFAULT_OPACITY = 0.5
export const MAPLAYER_ZOOM_THRESHOLD = 6.53
export const MAPLAYER_ZOOM_NAUGHT = 1.81378
