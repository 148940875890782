import { call, put, select } from 'redux-saga/effects'

import { get } from '../utils/api'
import { LIST_BASELINE } from '../constants/api'
import { receiveBaselineList } from '../reducers/baseline'
import { getBaselineSelectedId } from '../store/selectors'
import { recalculateSelectedConservationPractices } from './conservationPractice'
import { recalculateSelectedAnimalTypes } from './livestock'
import { LAND_USE_LIVESTOCK } from '../constants'

export function* listBaseline() {
  try {
    const data = yield call(get, LIST_BASELINE)
    yield put(receiveBaselineList(data))
  } catch (error) {
    console.warn('Error listing baseline types: ', error)
  }
}

export function* setBaseline() {
  try {
    const baselineLandUseId = yield select(getBaselineSelectedId)

    //if the map was moved while a different baseline was selected, results will only
    // have been recalculated for the baseline that was selected - need to recalculate
    // results for this new baseline
    yield call(
      LAND_USE_LIVESTOCK === baselineLandUseId
        ? recalculateSelectedAnimalTypes
        : recalculateSelectedConservationPractices
    )
  } catch (error) {
    console.warn('Error recalculating after setting baseline: ', error)
  }
}

export function* setFarmType() {
  try {
    const baselineLandUseId = yield select(getBaselineSelectedId)

    yield call(
      LAND_USE_LIVESTOCK === baselineLandUseId
        ? recalculateSelectedAnimalTypes
        : recalculateSelectedConservationPractices
    )
  } catch (error) {
    console.warn('Error recalculating after setting farm type: ', error)
  }
}
