import Header from './Header'
import Footer from './Footer'

import { Box } from '@mui/material'

const PlannerGlobalPage = props => {
  const { children } = { ...props }

  return (
    <Box width="100%">
      <Header />

      <Box width="100%" role="main">
        {children}
      </Box>

      <Footer />
    </Box>
  )
}

export default PlannerGlobalPage
