import {
  Box,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import componentStyles from '../../../assets/theme/views/admin/dashboard'
import {
  getManureStorageList,
  getAnimalTypesList,
  getAnimalTypesSelectedIds,
  getLivestockResults,
} from '../../../store/selectors'
import { useSelector, useDispatch } from 'react-redux'

import {
  updateBaselineManureStorage,
  updateBaselinePopulation,
  updateBaselineProductionSystem,
} from '../../../reducers/livestock'
import Input from '../../ui/Form'
import {
  AnimalBaselineManureInfo,
  AnimalBaselinePopulationInfo,
  AnimalBaselineProductionInfo,
} from '../../Information'
import { MiniHelp } from '../../../constants'

const AnimalBaselineManagement = () => {
  const { classes } = makeStyles()(componentStyles)()
  const dispatch = useDispatch()

  const manureStorageList = useSelector(getManureStorageList)
  const animalTypesList = useSelector(getAnimalTypesList)
  const selectedAnimalIds = useSelector(getAnimalTypesSelectedIds)
  const livestockResults = useSelector(getLivestockResults)

  const baselineManagementHeaders = [
    { name: 'Livestock Species' },
    { name: 'Population', info: <AnimalBaselinePopulationInfo /> },
    { name: 'Production System', info: <AnimalBaselineProductionInfo /> },
    { name: 'Manure Storage', info: <AnimalBaselineManureInfo /> },
  ]

  return (
    <TableContainer>
      <Box component={Table} alignItems="center" marginBottom="0 !important">
        <TableHead>
          <TableRow>
            {baselineManagementHeaders.map((header, i) => (
              <TableCell
                key={i + '_table_header'}
                classes={{
                  root: classes.tableCellRoot + ' ' + classes.tableCellRootHead,
                }}
                style={{ textTransform: 'none', whiteSpace: 'normal', minWidth: '150px' }}
              >
                {header.info ? (
                  <MiniHelp
                    header={header.name}
                    dialogHeader={header.name}
                    text={header.info}
                  />
                ) : (
                  header.name
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {animalTypesList.map(animalType => {
            const animalId = animalType.id
            const included = selectedAnimalIds.includes(animalId)
            if (!included) return null

            const manureList = manureStorageList.filter(
              m => m.livestockSpecies.id === animalId
            )
            const resultValue = livestockResults.find(r => r.id === animalId)

            return (
              <TableRow key={`baseline ${animalId}`}>
                <TableCell
                  classes={{
                    root: classes.tableCellRoot + ' ' + classes.tableCellRootBodyHead,
                  }}
                  style={{ maxWidth: '150px', minWidth: '150px' }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  {animalType.name}
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  style={{ maxWidth: '75px' }}
                >
                  <Input
                    key={`basePop${animalId}`}
                    type="number"
                    step="any"
                    style={{ textAlign: 'center', width: '8.5em' }}
                    min={0}
                    placeholder="Enter Population"
                    value={resultValue?.baselinePopulation || ''}
                    onChange={e => {
                      dispatch(
                        updateBaselinePopulation({
                          id: animalId,
                          population: parseFloat(e.target.value),
                        })
                      )
                    }}
                  />
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  sx={{ minWidth: '186px', width: '315px' }}
                >
                  <RadioGroup
                    row={false}
                    value={
                      resultValue?.baselineProductionSystem !== null &&
                      resultValue?.baselineProductionSystem !== undefined
                        ? resultValue?.baselineProductionSystem
                        : -1 // Since user selections are stored as IDs, and '0' is evaluated as falsy, need to handle things this way. This probably won't be an issue once the values are actually being pulled from the db, tho
                    }
                    onChange={e => {
                      dispatch(
                        updateBaselineProductionSystem({
                          id: animalId,
                          productionSystem: e.target.value,
                        })
                      )
                    }}
                    sx={{ width: '100%', marginBottom: '0 !important' }}
                  >
                    <FormControlLabel
                      value={49}
                      control={<Radio />}
                      label={
                        <Typography sx={{ minWidth: '90%', textWrap: 'auto' }}>
                          Low Production System
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={48}
                      control={<Radio />}
                      label={
                        <Typography sx={{ minWidth: '90%', textWrap: 'auto' }}>
                          High Production System
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  sx={{ maxWidth: '325px', width: '325px' }}
                >
                  <Select
                    variant="filled"
                    labelId="manure-storage-label"
                    value={
                      resultValue?.baselineManureStorage !== null &&
                      resultValue?.baselineManureStorage !== undefined
                        ? resultValue?.baselineManureStorage.id
                        : -1 // Same as above
                    }
                    onChange={e => {
                      const manureSystem = manureList.find(
                        ml => ml.manureSystem.id === e.target.value
                      ).manureSystem
                      dispatch(
                        updateBaselineManureStorage({
                          id: animalId,
                          manureStorage: manureSystem,
                        })
                      )
                    }}
                  >
                    <MenuItem value={-1} disabled>
                      <i>Select Manure Storage</i>
                    </MenuItem>
                    {manureList.map(ms => (
                      <MenuItem key={ms.manureSystem.id} value={ms.manureSystem.id}>
                        {ms.manureSystem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Box>
    </TableContainer>
  )
}
export default AnimalBaselineManagement
