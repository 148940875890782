export const ChangeList = [
  {
    Version: 'Planner Global Beta',
    Date: 'May 2023',
    MajorChange: `The first version of COMET-Planner Global was made publicly available in early May, 2023. 
        This version used IPCC Tier One methodology to provide an estimate for carbon sequestration based on farm type, 
        land use, and conservation practice selection. The tool initially only provided estimates for carbon dioxide (CO2) removals, 
        through mineral soils and/or woody biomass carbon stock increases, and directed the user to the COMET-Planner tool for more detailed estimates 
        for locations inside the contiguous United States. Estimates were made using broad climate and soil zones, developed by Colorado State University, 
        based on definitions provided by the IPCC.`,
    MinorChanges: [
      'Farm Types: Smallholder or Commercial/Plantation',
      'Land Use: Cultivated Annual Croplands or Pastures and Rangeland',
    ],
  },
  {
    Version: 'Planner Global Version 0.9',
    Date: 'October 2023',
    MajorChange:
      'In preparation for a presentation at the 28th meeting of the Conference of the Parties (COP) at the 2023 UN Climate Change Conference, a number of changes were made with the goal of improving user friendliness and functionality.',
    MinorChanges: [
      'Use popup in bottom corner of map to show soil and climate values for the currently selected location',
      'Only display country border when soil or climate layer view is selected in the legend',
      'Set location on click, instead of zooming to location',
      'Updated climate layers',
      'Added Agroforestry icon to land use list, to indicate upcoming feature',
      "Removed 'Beta' from the header bar",
    ],
  },
  {
    Version: 'Planner Global Earth Day Version 0.10',
    Date: 'April 2024',
    MajorChange: `As part of a release timed to coincide with Earth Day, 2024, the COMET-Planner Global tool was updated with several new features. These included a "Contact Us" submission form where users
        are able to provide feedback regarding their experience with the tool and any issues they may encounter, a preliminary version of a "Documentation" page that will be expanded in the future to include 
        additional information and documentation relevant to COMET-Planner Global, and the implementation of a number of Agroforestry conservation practices. It was determined to remove Agroforestry as an option 
        when selecting Current Land Use, and instead the decision was made to implement practices with the assumption of previous land use being either Cultivated Annual Croplands or Pastures and Rangeland. A complete 
        list of the practices added is shown below. Note that practices indicated with an asterisk (*) have both woody biomass and soil carbon sequestration benefits. Additionally, an option was added to the Report section 
        that displays a breakdown of the carbon sequestration value in tonnes CO2e/yr, indicating how the total value is computed.`,
    MinorChanges: [
      `Agroforestry Conservation Practices: Convert annual cropland to alley cropping, Convert annual cropland to fallow with trees, Convert strip of annual cropland to a hedgerow or windbreak, 
            Convert annual croland to multistrata system, Convert annual cropland to silvoarable system, Convert pasture or rangeland to silvopastoral system, and Convert strip of pasture or rangeland to a hedgerow or windbreak`,
    ],
  },
  {
    Version: 'Planner Global COP 2024 Version 0.11',
    Date: 'December 2024',
    MajorChange:
      'Enhanced the map experience with improved performance and support for larger view windows',
    // MajorChange: `As part of a release timed to coincide with COP 2024, the COMET-Planner Global tool was updated with several new features.`,
    MinorChanges: [],
  },
]
