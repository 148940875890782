import React, { useState } from 'react'
import { getX } from '../../../utils/getX'
import { Button } from '@mui/material'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import './PillFilters.css'

const PillFilters = props => {
  const [selectedIds, setSelectedIds] = useState([])

  const { pills: rawPills, onClick, ...rest } = props

  const pills = getX.array(rawPills)
  const selectedPills = pills.filter(p => selectedIds.includes(p.id))
  const unSelectedPills = pills.filter(p => !selectedIds.includes(p.id))

  function handlePillOnClickAdd(p) {
    setSelectedIds(prevIds => {
      onClick([...prevIds, p.id])
      return [...prevIds, p.id]
    })
  }
  function handlePillOnClickRemove(p) {
    setSelectedIds(prevIds => {
      onClick(prevIds.filter(id => id !== p.id))
      return prevIds.filter(id => id !== p.id)
    })
  }

  return (
    <div
      style={{
        width: 'fit-content',
        display: 'flex',
        alignItems: '',
        gap: '1em',
        paddingLeft: '1em',
      }}
      {...rest}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '0em' }}>
        {selectedPills.length > 0 ? (
          <IoIosCloseCircleOutline
            style={{ height: '2.25em', width: '2.25em', cursor: 'pointer' }}
            onClick={() => {
              setSelectedIds([])
              onClick([])
            }}
          />
        ) : (
          ''
        )}
        {selectedPills.map((pill, i, arr) => (
          <PillButton
            key={pill.label}
            sx={{
              backgroundColor: '#38545a33',
              display: 'flex',
              gap: '1em',
              px: '.75em',
              borderRadius: (() => {
                if (arr.length === 1) return '25px'
                if (i === 0) return '25px 0 0 25px'
                if (i === arr.length - 1) return ' 0 25px 25px 0 '
                return '0'
              })(),
              borderRight:
                arr.length > 1 && i !== arr.length - 1 ? '#38545a55 .25px solid' : '',
            }}
            onClick={() => handlePillOnClickRemove(pill)}
            label={pill}
            // children={'x'}
          />
        ))}
      </div>
      {false && selectedPills.length > 0 && unSelectedPills.length !== 0 && (
        <hr style={{ margin: '0 1em' }} />
      )}
      <div style={{ display: 'flex', gap: '.25em' }}>
        {unSelectedPills.map(pill => (
          <PillButton
            key={pill.label}
            sx={{ border: '.25px solid #38545a' }}
            onClick={() => handlePillOnClickAdd(pill)}
            label={pill}
          />
        ))}
      </div>
    </div>
  )
}

export default PillFilters

const PillButton = ({ label, sx, children, ...rest }) => (
  <Button
    {...rest}
    sx={{ py: '.3em', borderRadius: '25px', display: 'flex', gap: '1.5em', ...sx }}
  >
    <span
      className="pillfilter-label"
      style={{ display: 'flex', alignItems: 'center', gap: '.15em' }}
    >
      {label.icon}
      {label.label}
    </span>
    {children}
  </Button>
)
