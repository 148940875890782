import { useDispatch, useSelector } from 'react-redux'
import { setFarmType } from '../../reducers/baseline.js'
import { getFarmTypeId } from '../../store/selectors.js'
import { LARGE_FARM, SMALL_FARM } from '../../constants'
import { Button } from '../ui/Form'
import { Fragment } from 'react'

function FarmType() {
  const farmtypeId = useSelector(getFarmTypeId)
  const dispatch = useDispatch()

  const buttonClassName = id => (farmtypeId === id ? 'active' : '')

  const farmTypeOptions = [
    {
      label: SMALL_FARM,
      id: 1500,
    },
    {
      label: LARGE_FARM,
      id: 1501,
    },
  ]

  function updateFarmType(selection) {
    if (selection !== farmtypeId) {
      dispatch(setFarmType(selection))
    }
  }

  return (
    <span className="cpgl-farmtype">
      {farmTypeOptions.map((e, i) => (
        <Fragment key={`farmtype+${i}`}>
          <Button
            onClick={() => updateFarmType(e.id)}
            className={buttonClassName(e.id)}
            label={e.label}
            key={`farmtype_${i}`}
            style={{ border: 'var(--color-1) solid .25px !important' }}
          />
          {i + 1 < farmTypeOptions.length ? (
            <span
              className="cpgl-farmtype-or"
              style={{
                flex: '0',
                fontWeight: '600',
                fontSize: '1.2em',
                textAlign: 'center',
              }}
            >
              or
            </span>
          ) : (
            ''
          )}
        </Fragment>
      ))}
    </span>
  )
}

export default FarmType
