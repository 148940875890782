import { call, put, select } from 'redux-saga/effects'

import { get } from '../utils/api'
import { LOCATION_DATA } from '../constants/api'

import { getBaselineSelectedId } from '../store/selectors'
import { clearCoefficients } from '../reducers/conservationPractice'
import { receiveLocationData } from '../reducers/location'
import { recalculateSelectedConservationPractices } from './conservationPractice'
import { recalculateSelectedAnimalTypes } from './livestock'
import { LAND_USE_LIVESTOCK } from '../constants'
import { getX } from '../utils/getX'
import { LAYER_NAMES } from '../components/MapGeoserver/map.constants'

export function* setLocation(action) {
  const baselineLandUseId = yield select(getBaselineSelectedId)
  const { payload } = action
  const { coord, attribs } = payload
  const [lat, lon] = coord
  const locationData = getX.array(attribs).reduce(
    (acc, a) => {
      if (a.group === LAYER_NAMES.TERRITORY.group)
        // if (a.group === "territory")
        return {
          ...acc,
          territory: { ...a, name: a.value },
          countryId: a.id,
          regionId: a.id,
        }
      if (a.group === LAYER_NAMES.CLIMATE.group)
        return { ...acc, climate: { ...a, name: a.value } }
      /**
       * TODO: this needs to be fixed.
       * right now Ssoil (ssurgo) comes after Global soils so it overrides the soild ID.
       * Ultimate goal. if National (SSurgo) data available use it instead of Global Soils
       * but what do we do when nation is NO data (not undefined) and global has data?
       */
      if (
        a.group === LAYER_NAMES.SOIL_GLOBAL.group ||
        (a.group === LAYER_NAMES.SOIL_NATIONAL.group && a.id)
      )
        return { ...acc, soil: { ...a, name: a.value } }
      return { ...acc, [a.group]: { ...a } }
    },
    { lat, lon }
  )
  if (lat && lon) {
    //clear the results values and location data
    yield put(clearCoefficients())
    yield put(receiveLocationData({}))

    try {
      // const locationData = yield call(get, LOCATION_DATA, { lat, lon });
      yield put(receiveLocationData(locationData))
      yield call(
        LAND_USE_LIVESTOCK === baselineLandUseId
          ? recalculateSelectedAnimalTypes
          : recalculateSelectedConservationPractices
      )
    } catch (error) {
      console.warn('Error setting location: ', error)
    }
  }
}
