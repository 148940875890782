// Redux
import { useDispatch, useSelector } from 'react-redux'

// Mui components
import { makeStyles } from 'tss-react/mui'
import {
  Alert,
  AlertTitle,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

// Theme styles
import componentStyles from '../../assets/theme/views/admin/dashboard.js'

// Reducers
import {
  selectConservationPractice,
  deselectConservationPractice,
} from '../../reducers/conservationPractice.js'
import {
  getAgroforestryList,
  getBaselineSelectedId,
  getConservationList,
  getConservationSelectedIds,
  getFarmTypeId,
  getLocation,
} from '../../store/selectors.js'

// Helpers and Calls
import { getX } from '../../utils/getX.js'
import {
  displayPractice,
  displayAgroforestryPractice,
  getLandUseIdFromBaselineId,
} from '../../utils/helpers.js'
import { BASELINE_LANDUSE_TXT, FARM_LOCATION, FARM_TYPE } from '../../constants'

const HighlightedText = ({ txt }) => <b style={{ fontSize: '1.051em' }}> {txt} </b>

function ConservationPracticeCard() {
  const { classes } = makeStyles()(componentStyles)()
  const conservationList = useSelector(getConservationList)
  const agroforestryList = useSelector(getAgroforestryList)
  const conservationSelectedIds = useSelector(getConservationSelectedIds)
  const location = useSelector(getLocation)
  const farmTypeId = useSelector(getFarmTypeId)
  const baselineLandUseId = useSelector(getBaselineSelectedId)
  const dispatch = useDispatch()

  // This strategy may need to change as we add more baseline classes. Currently the
  // baselineLandUseClasses in the ConservationPractice list divide nicely along Baseline Practices
  // (e.g. baselineLandUseClass 9708 always lines up with Cropland and 9702 always lines up with
  // Pasture/Rangeland), but as we add in more baseline classes that may not always be the case.
  // We may end up needing to add a BaselinePractice field to the ConservationPractice object
  // that contains the lookup id of the Baseline Practice it's associated with, and then pass in
  // baselineSelectedId to the displayPractice function and filter by that instead of landUseClassId.
  const landUseClassId = getLandUseIdFromBaselineId(baselineLandUseId)

  const updateConservation = id => () => {
    const currentIndex = conservationSelectedIds.indexOf(id)

    if (currentIndex === -1) {
      //id is not in list, add it
      dispatch(selectConservationPractice(id))
    } else {
      //remove id from list
      dispatch(deselectConservationPractice(id))
    }
  }

  const conservationPractices = farmTypeId
    ? getX
        .arrayCloneSort('name', conservationList)
        .filter(item => !agroforestryList.some(ap => ap.name === item.name))
        .filter(item => displayPractice(item, location, landUseClassId))
    : []

  const agroForestryPractices = farmTypeId
    ? getX
        .arrayCloneSort('name', agroforestryList)
        .filter(item =>
          displayAgroforestryPractice(item, location, baselineLandUseId, farmTypeId)
        )
    : []

  const ConservationPracticeSubCategoryHeader = ({ label }) => (
    <AlertTitle style={{ padding: '1em 0 0 .5em' }}>
      <h2>{label}</h2>
    </AlertTitle>
  )
  const ConservationPracticeSubCategories = item => (
    <ListItem
      key={item.id}
      onClick={updateConservation(item.id)}
      dense
      button
      role={undefined}
      classes={{ root: classes.muiListItemRoot }}
    >
      <ListItemIcon classes={{ root: classes.muiListItemIconRoot666 }}>
        <Checkbox edge="start" checked={conservationSelectedIds.indexOf(item.id) !== -1} />
      </ListItemIcon>
      <ListItemText primary={item?.name} />
    </ListItem>
  )
  const AwaitUserSelectionsMessage = () => (
    <Alert severity="warning" className="prose">
      <AlertTitle>
        <h3>Selections Missing</h3>
      </AlertTitle>
      {!(location?.lat && location?.lon) && <li>Please Select Farm Location on the map</li>}
      {!farmTypeId && <li>Please Select a Farm Type</li>}
      {!landUseClassId && <li>Please Select a current Land Use</li>}
    </Alert>
  )
  const NoConservationPracticesAvailableMessage = () => (
    <div className="no-practice-warning">
      <Alert severity="info">
        <AlertTitle>
          <h3>
            {landUseClassId === '314159265_agroforestry_landuseID_placeholder'
              ? 'Agroforesty Coming Soon'
              : 'No Conservation Practices available'}
          </h3>
        </AlertTitle>
        <br />
        Please try a different
        <HighlightedText txt={FARM_TYPE + ','} />
        <HighlightedText txt={FARM_LOCATION + ','} /> and
        <HighlightedText txt={BASELINE_LANDUSE_TXT} />
        combination
      </Alert>
    </div>
  )

  return (
    <ul
      id="conservationpractice"
      style={{
        width: '100%',
        padding: '.5em',
        overflow: 'auto',
        position: 'relative',
        height: '100%',
      }}
    >
      <div>
        {farmTypeId && landUseClassId && location?.lat && location?.lon && (
          <div className="practice-list">
            {/* If a farm type has been selected, sort and filter the list of conservation practices and display */}
            <div>
              <ConservationPracticeSubCategoryHeader
                label={
                  baselineLandUseId === 1
                    ? 'Croplands Management'
                    : 'Pastures and Rangeland Management'
                }
              />
              {conservationPractices.length > 0 ? (
                <div>
                  {conservationPractices.map(item => (
                    <ConservationPracticeSubCategories {...item} key={item.id} />
                  ))}
                </div>
              ) : (
                <NoConservationPracticesAvailableMessage />
              )}
            </div>
            {/* Also, display agroforestry (biomass C) practices that don't share a name with previously added soil c practices */}
            <div>
              <ConservationPracticeSubCategoryHeader label="Agroforestry" />
              {agroForestryPractices.length > 0 ? (
                <div>
                  {agroForestryPractices.map(item => (
                    <ConservationPracticeSubCategories {...item} key={item.id} />
                  ))}
                </div>
              ) : (
                <NoConservationPracticesAvailableMessage />
              )}
            </div>
          </div>
        )}
        {/* If the user hasn't selected a farm type, current land use, or location, display corresponding error messages */}
        {!(farmTypeId && landUseClassId && location?.lat && location?.lon) && (
          <AwaitUserSelectionsMessage />
        )}
      </div>
    </ul>
  )
}

export default ConservationPracticeCard
