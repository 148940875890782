// @ts-nocheck
import React, { ReactNode } from 'react'
import { IdType, AnyFunction } from '../map.typedefs'
import { IconButton as IconButtonMui } from '@mui/material'

type ToggleButtonType = { first?: ReactNode; second?: ReactNode; onChange?: AnyFunction }
export const ToggleButton = ({ first, second, onChange }: ToggleButtonType) => {
  return (
    <label className="inline-flex items-center mb-5 cursor-pointer gap-3">
      <span className=" text-sm font-medium text-gray-900 dark:text-gray-300">{first}</span>
      <input type="checkbox" className="sr-only peer" onChange={onChange} />
      <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-2  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-blue-500 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-blue-500 after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 "></div>
      {/* <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div> */}
      <span className=" text-sm font-medium text-gray-900 dark:text-gray-300">{second}</span>
    </label>
  )
}
// prettier-ignore
type SliderBar = {defaultValue:number; label?: ReactNode; id?: IdType; onChange?: AnyFunction } & React.HTMLProps<HTMLDivElement>
export const SliderBar = ({ defaultValue, label, id, onChange, ...rest }: SliderBar) => {
  return (
    <div {...rest}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        className="ui-range-input"
        defaultValue={defaultValue}
        onChange={onChange}
        id={id}
        type="range"
        style={{
          width: '90%',
          cursor: 'pointer',
          backgroundColor: 'lightgrey',
          borderRadius: '.25rem',
          height: '0',
          appearance: 'none',
          border: 'none',
        }}
      />
    </div>
  )
}

type ButtonType = { label?: ReactNode } & React.HTMLProps<HTMLButtonElement>

export const ButtonCircle = ({ label, children, className, ...rest }: ButtonType) => {
  return (
    <button
      {...rest}
      type="button"
      className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${className}`}
    >
      {label}
      {children}
      <span className="sr-only">{rest.title}</span>
    </button>
  )
}

type IconButtonProps = {
  label?: React.ReactNode
  classNameWrap?: string
} & React.ComponentProps<typeof IconButtonMui>

export const IconButton = ({ label, children, classNameWrap, ...rest }: IconButtonProps) => {
  return (
    <div className={` ${classNameWrap}`}>
      <IconButtonMui {...rest} type="button" aria-label="center map">
        {label}
        {children}
      </IconButtonMui>
    </div>
  )
}
