import React, { useState } from 'react'
import { getX } from '../../utils/getX'
import { Link as LinkMui } from '@mui/material'

export const Button = props => {
  const attribs = (({ className, title, role, onClick, style, disabled }) => ({
    className,
    title,
    role,
    onClick,
    style,
    disabled,
  }))(props)
  return (
    <button {...attribs} type="button">
      {props.children}
      {props.label && <span {...props.icon}>{getX.value(props.label)}</span>}
    </button>
  )
}

/**
 * Element @RequiredInfo signals user that the field is required.
 * Prop @showMessage shows more or less information to end user
 * prop @message replaces text "Required" if desired
 */
export const RequiredInfo = props => {
  const { dataValidated, showMessage, message } = props

  return (
    <span style={{ ...{ color: 'var(--cancel-color,red)' }, ...props.style }}>
      {dataValidated ? '' : `* ${showMessage ? message || 'Required' : ''}`}
    </span>
  )
}

const PasswordInput = props => {
  const [showPw, setShowPw] = useState(false)

  const attribs = (({ placeholder, value }) => ({
    placeholder,
    value,
  }))(props)

  return (
    <label className={`ax-input password ${props.className}`}>
      {props.label}
      <div className="passwordWrap ax-flex">
        <input
          type={showPw ? 'text' : 'password'}
          style={{ border: 'none', outlineColor: 'transparent', flexGrow: 1 }}
          {...attribs}
          className={`cfarm-input-generic ${props.className}`}
        />
        <Button
          style={{ cursor: 'pointer', marginRight: '0.5em' }}
          // label={showPw ? 'hide' : 'show'}
          onClick={e => {
            e.preventDefault()
            setShowPw(!showPw)
          }}
          className="show-password ax-unset"
        >
          {showPw ? 'hide' : 'show'}
        </Button>
      </div>
    </label>
  )
}

const RadioDial = props => {
  const { label, style, className, id, name, value } = props
  return (
    <label className={`ax-flex no-gap ${className}`} style={style}>
      <input type="radio" id={id} name={name} value={value} />
      {label}
    </label>
  )
}
const CheckBox = props => {
  const [checked, setChecked] = useState(props.checked)
  const attribs = (({ style, className, id, name, value }) => ({
    style,
    className,
    id,
    name,
    value,
  }))(props)

  return (
    <label className={`ax-input ax-flex`}>
      <span>
        <input
          type="checkbox"
          {...attribs}
          checked={checked}
          onChange={() => setChecked(e => !e)}
        />
      </span>
      <span style={{ flexGrow: 1 }}>{props.label}</span>
    </label>
  )
}

export const Input = props => {
  const { type } = props

  if (type === 'password') return <PasswordInput {...props} />

  if (type === 'submit') {
    const submit_attribs = (({ label, style, value }) => ({
      label,
      style,
      value,
    }))(props)

    return (
      <input
        className={`ax-input ${props.className}`}
        {...submit_attribs}
        value={submit_attribs.value || submit_attribs.label || 'Submit'}
        type="submit"
      />
    )
  }
  if ('checkbox' === type) return <CheckBox {...props} />

  if (type === 'radio') return <RadioDial {...props} />
  if (type === 'radiogroup') {
    return (
      <div {...basicAttribs(props)} role="radiogroup">
        {props.radioList.map((e, i) => (
          <React.Fragment key={`radiogroup_${e.label}_${i}`}>
            <RadioDial {...e} name={props.name} />
          </React.Fragment>
        ))}
      </div>
    )
  }

  return (
    <label className={`ax-input ${type} ${props.className}`}>
      {props.label}
      {(type => {
        switch (type) {
          case 'textarea':
            const attribs_textarea = (({ placeholder, name, id, value }) => ({
              placeholder,
              name,
              id,
              value,
            }))(props)

            return (
              <textarea
                style={{ width: '100%' }}
                {...attribs_textarea}
                className={`cfarm-input-generic ${props.className}`}
              />
            )

          case 'text':
            const attribs_text = (({ placeholder, name, value }) => ({
              placeholder,
              name,
              value,
            }))(props)

            return (
              <input {...attribs_text} className={`cfarm-input-generic ${props.className}`} />
            )

          case 'select':
            return (
              <select
                value={props.value}
                onChange={props.onChange}
                style={props.style?.select}
              >
                {getX.array(props.options).map(option => (
                  <option style={props.style?.option} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            )
          default:
            return <input {...props} className={`cfarm-input-generic ${props.className}`} />
        }
      })(type)}
    </label>
  )
}

export const Link = props => {
  const { label, children, ...rest } = props
  return (
    <>
      {' '}
      <LinkMui target="_blank" rel="noopener noreferrer" {...rest}>
        {label} {children}
      </LinkMui>{' '}
    </>
  )
}

export const LinkButton = props => {
  const attribs = (({ className, href, style }) => ({
    className,
    href,
    style,
  }))(props)
  return (
    <a
      {...attribs}
      target="_blank"
      className={`link-btn ${attribs.className}`}
      rel="noopener noreferrer"
    >
      <div style={{ width: '100%', textAlign: 'left', padding: '.5em .25em' }}>
        {props.children}
        {props.label}
      </div>
    </a>
  )
}

/**
 *  @NavLink is broken....DO NOT USE for now
 */
export const NavLink = React.forwardRef(
  ({ className, onClick, handleOnClick, href, label }, ref) => {
    return (
      <></>
      // <NextLink
      //   {...{ className: `form-navlink ${className}`, onClick, ref }}
      //   href={href || '/'}
      // >
      //   {label}
      // </NextLink>
    )
  }
)

NavLink.displayName = 'NavLink'

export default Input

function basicAttribs(props) {
  return (({ className, title, role, onClick, style, onChange }) => ({
    className,
    title,
    role,
    onClick,
    style,
    onChange,
  }))(props)
}
