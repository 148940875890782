import React from 'react'
import BasicTable from '../../../ui/BasicTable'
import { coefKeys } from './detseq.utils'
import Dialog from '../../../ui/Dialog'
import { CarbonSequestrationKey, TonnesCo2ePerYearLong } from '../CarbonSequestrationKey.jsx'
import './DetailedSequestration.css'

import { Button } from '@mui/material'

const DetailedSequestration = ({ tableRows, cat, isLivestock }) => {
  const filteredCoeffs = coefKeys.filter(
    ({ category }) => category === cat || category === 'all'
  )
  const tableKeys = filteredCoeffs.map(k => k.id)
  const tableHeader = filteredCoeffs.reduce(
    (acc, k) => ({
      ...acc,
      [k.id]: {
        value: <div dangerouslySetInnerHTML={{ __html: k.label }} />,
        attributes: {
          sx: {
            border: 'none',
            textTransform: 'none',
            fontWeight: 'bold',
            alignContent: 'center',
          },
        },
      },
    }),
    {}
  )

  const disableDetailedResultsBtn =
    !(Array.isArray(tableRows) && tableRows.length > 0) ||
    !tableRows.every(e => e.area || (e.category === 'livestock' && e.changeTotal !== null))

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: 'unset',
          },
        },
      }}
      btnProps={{
        component: Button,
        style: { color: 'white', backgroundColor: 'var(--color-2)' },
        children: 'Detailed Report',
        disabled: disableDetailedResultsBtn,
        title: disableDetailedResultsBtn
          ? 'Please Enter All Field Sizes'
          : 'Detailed Carbon Sequestration and Greenhouse Gas Emission Reductions Report',
      }}
      header={
        <>
          Detailed Carbon Sequestration & Greenhouse Gas Emission Reductions
          <div style={{ textTransform: 'none', paddingLeft: '.25em' }}>
            <TonnesCo2ePerYearLong />
            {/* <TonnesCo2ePopup /> */}
          </div>
        </>
      }
      footer={<CarbonSequestrationKey isLivestock={isLivestock} showNotEstimated={true} />}
      style={{ maxWidth: '1660px', margin: 'auto' }}
    >
      <BasicTable
        {...{
          tableHeader,
          tableKeys,
          tableRows,
          className: isLivestock
            ? 'animal-table glob-detailed-sequestration-table'
            : 'basic-table glob-detailed-sequestration-table',
        }}
      />
    </Dialog>
  )
}

export default DetailedSequestration
